/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import { forwardRef } from 'react';

const ProductImage = forwardRef(
	(
		{ product, productIsTooDeep, productIsTooWide, productIsTooHigh, isFullSize = false },
		ref
	) => {
		return (
			<img
				ref={ref}
				src={product.rotated ? product.imageUrl2 : product.imageUrl}
				css={css`
					max-width: 100%;
					max-height: 100%;
					pointer-events: ${productIsTooDeep || productIsTooWide || productIsTooHigh
						? 'none'
						: 'inherit'};
				`}
				width={!isFullSize ? (product.rotated ? product.depth : product.width) : 'auto'}
				height={!isFullSize ? product.height : 'auto'}
				alt="Test Product"
			/>
		);
	}
);

export default ProductImage;
