/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';

import { useCallback, useMemo, useState, useEffect, Fragment, useRef } from 'react';

import Block from '../../box-model/Block';
import Flex from '../../box-model/Flex';

import SingleSelect from '../../input/SingleSelect';

import ProductSelector from '../../product/ProductSelector';

import editIconUrl from './boxes/edit-icon.svg';
import rotateIconUrl from './boxes/rotate-icon.svg';

import ColorsPane from './boxes/ColorsPane';
import Color from './boxes/Color';

import Pane from './Pane';

import closeIconUrl from './close-icon.svg';

const Heading3 = styled.h3`
	font-family: 'Roboto';
	font-size: 14px;
	font-weight: bold;
	text-align: left;
	margin: 0 0 5px 0;
	color: var(--purple-brown);
`;

const RotateButton = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	cursor: pointer;

	font-family: 'Roboto';
	font-size: 14px;
	letter-spacing: normal;
	text-align: left;
	color: rgba(130, 90, 93, 0.5);

	transition: color 0.2s ease-in-out;
	:hover {
		color: rgba(130, 90, 93, 1);
	}
`;

const RedPopup = styled.div`
	position: absolute;
	top: 25px;
	right: -245px;
	width: 223px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
	background-color: var(--pastel-red);
	border-radius: 10px;
	padding: 5px 10px 10px 10px;
`;

const TooltipHeading = styled.h3`
	font-family: 'Roboto';
	font-size: 20px;
	font-weight: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ffffff;
	margin: 0;
`;

const TooltipText = styled.p`
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 300;
	line-height: 1.25;
	color: #ffffff;
	margin: 0;
	white-space: pre-wrap;
`;

const PopupCloseButton = styled.div`
	cursor: pointer;

	> img {
		opacity: 0.5;
		transition: opacity 0.2s ease-in-out;

		:hover {
			opacity: 1;
		}
	}
`;

let lastSelectedSeriesGuid = null;
let lastSelectedColorGuid = null;
let lastRotated = null;

export default function BoxesPane({
	auth,
	currentLocale,
	currentCloset,
	getTextContent,
	isColorsPaneVisible,
	onBeginDrag,
	onClosePaneButtonClick,
	openInfoModal,
	redInfoPopupIsVisible,
	setIsColorsPaneVisible,
	setRedInfoPopupIsVisible
}) {
	const [seriesOptions, setSeriesOptions] = useState(null);

	const [currentSeriesGuid, setCurrentSeriesGuid] = useState(lastSelectedSeriesGuid);
	const handleSeriesSelect = useCallback(event => {
		lastSelectedSeriesGuid = event.target.value;
		setCurrentSeriesGuid(event.target.value);
	}, []);

	const currentSeries = useMemo(() => {
		if (!seriesOptions || !currentSeriesGuid) {
			return { name: '' };
		}

		return seriesOptions.find(option => option.guid === currentSeriesGuid);
	}, [currentSeriesGuid, seriesOptions]);

	const [colorOptions, setColorOptions] = useState(null);

	const [currentColorGuid, setCurrentColorGuid] = useState(lastSelectedColorGuid);
	const handleColorSelect = useCallback(color => {
		lastSelectedColorGuid = color.guid;
		setCurrentColorGuid(color.guid);
	}, []);

	const currentColor = useMemo(() => {
		if (!colorOptions || !currentColorGuid) {
			return { name: '', colour1: '#ffffff', colour2: '#ffffff' };
		}

		return colorOptions.find(option => option.guid === currentColorGuid);
	}, [colorOptions, currentColorGuid]);

	const [isRotated, setIsRotated] = useState(lastRotated);

	const toggleIsRotated = useCallback(() => {
		setProducts(products => products.map(product => ({ ...product, rotated: !isRotated })));
		lastRotated = !isRotated;
		setIsRotated(!isRotated);
	}, [isRotated]);

	useEffect(() => {
		auth.fetch(`/productserie?languageCode=${currentLocale}`)
			.then(response => response.json())
			.then(series => {
				// console.log('GET PRODUCT SERIES RESPONSE JSON', series);

				if (series && Array.isArray(series)) {
					setSeriesOptions(series);
					setCurrentSeriesGuid(lastSelectedSeriesGuid || series[0].guid);
				} else {
					// console.log('GET PRODUCT SERIES UNEXPECTED RESPONSE JSON', series);
				}
			})
			.catch(error => {
				// console.log('GET PRODUCT SERIES ERROR', error);
			});
	}, [auth, currentLocale]);

	useEffect(() => {
		if (!currentSeriesGuid) {
			return;
		}

		auth.fetch(`/colour?productserieGuid=${currentSeriesGuid}&languageCode=${currentLocale}`)
			.then(response => response.json())
			.then(colors => {
				// console.log('GET COLOURS RESPONSE JSON', colors);

				if (colors && Array.isArray(colors)) {
					setColorOptions(colors);
					setCurrentColorGuid(lastSelectedColorGuid || colors[0].guid);
				} else {
					// console.log('GET COLOURS UNEXPECTED RESPONSE JSON', colors);
				}
			})
			.catch(error => {
				// console.log('GET COLOURS ERROR', error);
			});
	}, [auth, currentLocale, currentSeriesGuid]);

	const [products, setProducts] = useState(null);
	useEffect(() => {
		if (!currentSeriesGuid || !currentColorGuid) {
			setProducts([]);
		} else {
			auth.fetch(
				`/product?productserieGuid=${currentSeriesGuid}&colourGuid=${currentColorGuid}&languageCode=${currentLocale}`
			)
				.then(response => response.json())
				.then(products => {
					// console.log('GET PRODUCTS RESPONSE JSON', products);

					if (products && Array.isArray(products)) {
						products = products.map(product => ({
							...product,
							rotated: isRotated,
							getSize: product => (product.rotated ? product.depth : product.width)
						}));
						setProducts(products);
					} else {
						// console.log('GET productserie UNEXPECTED RESPONSE JSON', products);
					}
				})
				.catch(error => {
					// console.log('GET PRODUCTS ERROR', error);
				});
		}
	}, [auth, currentColorGuid, currentLocale, currentSeriesGuid, isRotated]);

	const tooltipPortalRef = useRef();

	const deBoxenTitel = getTextContent('deBoxenTitel');
	const deBoxenSubtitel = getTextContent('deBoxenSubtitel');
	const deBoxenProductlijn = getTextContent('deBoxenProductlijn');
	const deBoxenKleur = getTextContent('deBoxenKleur');
	const deBoxenKleurenVoor = getTextContent('deBoxenKleurenVoor') || 'Kleuren voor';
	const deBoxenBezigMetLaden =
		getTextContent('deBoxenBezigMetLaden') || 'Bezig met het laden van de producten…';
	const deBoxenBoxen = getTextContent('deBoxenBoxen');
	const deBoxenBoxenDraaien = getTextContent('deBoxenBoxenDraaien');
	const deBoxenUitlegTitel = getTextContent('deBoxenUitlegTitel');
	const deBoxenUitlegTekst = getTextContent('deBoxenUitlegTekst');
	const deBoxenGeenProducten =
		getTextContent('deBoxenGeenProducten') ||
		'Er zijn geen producten in de gekozen productlijn en kleur.';
	const deBoxenKiesAndere =
		getTextContent('deBoxenKiesAndere') || 'Kies een andere kleur of productlijn.';

	return (
		<Pane
			heading={deBoxenTitel}
			onClosePaneButtonClick={onClosePaneButtonClick}
			subHeading={deBoxenSubtitel}
		>
			<Flex flexDirection="row" spaceHorizontal="15px">
				<Block flex="1">
					<Heading3>{deBoxenProductlijn}</Heading3>

					{currentSeriesGuid !== null && seriesOptions && seriesOptions.length > 0 && (
						<SingleSelect
							value={currentSeriesGuid}
							options={seriesOptions}
							onChange={handleSeriesSelect}
						/>
					)}
				</Block>

				<Block flex="none">
					<Heading3>{deBoxenKleur}</Heading3>

					<Flex
						flexDirection="row"
						alignItems="center"
						cursor="pointer"
						onClick={() => {
							setRedInfoPopupIsVisible(false);
							setIsColorsPaneVisible(isColorsPaneVisible => !isColorsPaneVisible);
						}}
					>
						{currentColor && <Color colorOption={currentColor} />}

						<img src={editIconUrl} width={24} height={24} alt="bewerken icoon" />
					</Flex>

					{currentSeries.name && colorOptions && isColorsPaneVisible && (
						<ColorsPane
							currentColor={currentColorGuid}
							deBoxenKleurenVoor={deBoxenKleurenVoor}
							colorOptions={colorOptions}
							onClosePaneButtonClick={() => setIsColorsPaneVisible(false)}
							onColorSelect={handleColorSelect}
							seriesLabel={currentSeries.name}
						/>
					)}
				</Block>
			</Flex>

			{products !== null && (
				<Fragment>
					<Flex
						flexDirection="row"
						alignItems="flex-end"
						justifyContent="space-between"
						marginTop="15px"
						marginBottom="5px"
						ref={tooltipPortalRef}
						zIndex={10}
					>
						<Heading3>{deBoxenBoxen}</Heading3>

						{products.length > 0 && (
							<RotateButton onClick={toggleIsRotated}>
								<img
									src={rotateIconUrl}
									width={24}
									height={24}
									alt="draaien icoon"
								/>{' '}
								<span>{deBoxenBoxenDraaien}</span>
							</RotateButton>
						)}

						{redInfoPopupIsVisible && products.length > 0 && (
							<RedPopup>
								<Flex
									flexDirection="row"
									justifyContent="space-between"
									alignItems="flex-end"
								>
									<TooltipHeading>{deBoxenUitlegTitel}</TooltipHeading>

									<PopupCloseButton
										onClick={() => setRedInfoPopupIsVisible(false)}
									>
										<img
											src={closeIconUrl}
											width={24}
											height={24}
											alt="sluiten icoon"
										/>
									</PopupCloseButton>
								</Flex>

								<TooltipText>{deBoxenUitlegTekst}</TooltipText>
							</RedPopup>
						)}
					</Flex>

					<ProductSelector
						currentCloset={currentCloset}
						currentColor={currentColor}
						currentSeries={currentSeries}
						getTextContent={getTextContent}
						deBoxenBezigMetLaden={deBoxenBezigMetLaden}
						deBoxenGeenProducten={deBoxenGeenProducten}
						deBoxenKiesAndere={deBoxenKiesAndere}
						onBeginDrag={onBeginDrag}
						openInfoModal={openInfoModal}
						products={products}
						tooltipPortalRef={tooltipPortalRef}
					/>
				</Fragment>
			)}
		</Pane>
	);
}
