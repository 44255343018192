/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';

import { forwardRef, useCallback, useState } from 'react';

import errorIconUrl from './error-icon.svg';
import successIconUrl from './success-icon.svg';

const styles = css`
	position: relative;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	height: 40px;
	border-radius: 5px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
	background-color: #ffffff;

	padding-left: 10px;
	padding-right: 10px;

	> :not(:last-child) {
		margin-right: 8px;
	}

	input {
		transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
		border: none;
		outline: none;

		flex: 1;

		font-family: 'Roboto';
		font-size: 16px;
		line-height: 1.25;

		color: var(--purple-brown);
	}
	input::placeholder {
		color: var(--purple-brown);
		opacity: 0.5;
		font-weight: normal;
	}
`;
const boldStyles = css`
	font-weight: bold;
`;

const FormFieldLabel = styled.span`
	transition: color 0.2s ease-in-out;
	position: absolute;
	top: -20px;
	left: 0;

	font-family: 'Roboto';
	font-size: 14px;
	font-weight: bold;
	line-height: 1.43;
	text-align: left;
	margin-bottom: 0;
	color: ${props => {
		if (props.state === 'error') {
			return 'var(--pastel-red)';
		} else if (props.state === 'success') {
			return 'var(--greeny-blue)';
		}
		return 'var(--purple-brown)';
	}};
`;

const SingleLineInput = forwardRef(
	(
		{
			autocomplete,
			onChange,
			isValid = () => true,
			placeholder,
			readOnly = false,
			type,
			value
		},
		ref
	) => {
		const [isFocused, setIsFocused] = useState(false);
		const [state, setState] = useState(null);

		const validateInput = useCallback(
			v => {
				switch (type) {
					default:
						if (!v || v.trim() === '' || !isValid(v.trim())) {
							setState('error');
							break;
						}
						setState('success');
						break;
				}
			},
			[isValid, type]
		);

		const handleChange = useCallback(
			event => {
				onChange(event.target.value);
				validateInput(event.target.value);
			},
			[onChange, validateInput]
		);

		const handleFocus = useCallback(_event => {
			setIsFocused(true);

			setState(null);
		}, []);

		const handleBlur = useCallback(
			_event => {
				setIsFocused(false);

				validateInput(value);
			},
			[validateInput, value]
		);

		return (
			<label css={styles}>
				{(isFocused || state !== null) && (
					<FormFieldLabel state={state}>{placeholder}</FormFieldLabel>
				)}

				<input
					type={type}
					css={type === 'password' && boldStyles}
					onChange={!readOnly ? handleChange : null}
					onFocus={!readOnly ? handleFocus : null}
					onBlur={!readOnly ? handleBlur : null}
					autoComplete={autocomplete}
					placeholder={isFocused || state !== null ? null : placeholder}
					ref={ref}
					readOnly={readOnly}
					value={value}
				/>

				{state === 'error' && (
					<img src={errorIconUrl} width={24} height={24} alt="invoerfout icoon" />
				)}

				{state === 'success' && (
					<img src={successIconUrl} width={24} height={24} alt="invoer succes icoon" />
				)}
			</label>
		);
	}
);

export default SingleLineInput;
