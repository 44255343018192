/** @jsx jsx */
import { css, jsx } from '@emotion/core';

const buttonStyles = css`
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: flex-start;

	height: 40px;

	outline: none;
	border: none;
	border-radius: 5px;
	padding-left: 10px;
	padding-right: 15px;

	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 500;
	line-height: 1.88;
	color: #ffffff;
	z-index: 1;

	cursor: pointer;

	transition: background-color 0.2s ease-out, border-color 0.2s ease-out, color 0.2s ease-out;

	svg {
		margin-right: 4px;
	}

	color: var(--dark-mauve);
	border: solid 2px var(--dark-mauve);
	background-color: #ffffff;

	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);

	transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
		color 0.2s ease-in-out;
	:hover {
		border-color: #772d33;
		background-color: #772d33;
		color: #ffffff;
	}
`;

export default function CopyButton({ label, onClick }) {
	return (
		<button css={buttonStyles} onClick={onClick}>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<path
					d="M16 1H4a2.006 2.006 0 0 0-2 2v14h2V3h12zm-1 4H8a2 2 0 0 0-1.99 2L6 21a2 2 0 0 0 1.99 2H19a2.006 2.006 0 0 0 2-2V11zM8 21V7h6v5h5v9z"
					fill="currentColor"
				/>
			</svg>

			<span>{label}</span>
		</button>
	);
}
