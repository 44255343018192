import React from 'react';

import css from '@emotion/css';
import { Global } from '@emotion/core';

import display from '../box-model/display';

const globalStyles = css`
	html {
		font-family: HelveticaNeue, 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
		font-size: 16px;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	html,
	body,
	#root {
		margin: 0;
		padding: 0;

		height: 100%;
		overflow: hidden;
	}
	body,
	#root {
		${display('flex')}

		flex: 1;
	}
	label {
		display: block;
	}
	::-ms-tooltip {
		display: none;
	}
	:root {
		--pastel-red: #e85a5a;
		--greeny-blue: #4acba3;
		--purple-brown: #401b1d;
		--dark-mauve: #825a5d;
		--warm-grey: #707070;
		--pale-grey: #f4f9f9;
	}
	a,
	a:link,
	a:hover,
	a:visited {
		text-decoration: none;
	}
	:any-link,
	:-webkit-any-link {
		text-decoration: none;
	}
`;

export default function GlobalStyles() {
	return <Global styles={globalStyles} />;
}
