/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Link } from 'react-router-dom';

const buttonStyles = css`
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: flex-start;

	height: 40px;

	outline: none;
	border: none;
	border-radius: 5px;
	padding-left: 10px;
	padding-right: 15px;

	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 500;
	line-height: 1.88;
	color: #ffffff;
	z-index: 1;

	cursor: pointer;

	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

	> * + * {
		margin-left: 4px;
	}
`;
const primaryButtonStyles = css`
	${buttonStyles}
	background-color: var(--pastel-red);

	:hover {
		background-color: #e33131;
	}
`;
const secondaryButtonStyles = css`
	${buttonStyles}
	background-color: var(--dark-mauve);

	:hover {
		background-color: #772d33;
	}
`;
const tertiaryButtonStyles = css`
	${buttonStyles}
	border: solid 2px var(--dark-mauve);
	color: var(--dark-mauve);
	background-color: #ffffff;

	:hover {
		border-color: #772d33;
		background-color: #772d33;
		color: #ffffff;
	}
`;

export default function Button({
	label = '',
	iconAlt = '',
	iconUrl = '',
	isDisabled = false,
	onClick = null,
	tag = 'button',
	to = null,
	type = 'primary'
}) {
	let styles = null;
	switch (type) {
		case 'secondary':
			styles = secondaryButtonStyles;
			break;
		case 'tertiary':
			styles = tertiaryButtonStyles;
			break;
		default:
			styles = primaryButtonStyles;
	}

	if (!iconUrl) {
		styles = css`
			${styles};
			padding-left: 15px;
		`;
	}

	if (!label) {
		styles = css`
			${styles};
			padding-right: 10px;
		`;
	}

	if (isDisabled) {
		styles = css`
			${styles};
			cursor: default;
			pointer-events: none;
			opacity: 0.5;
		`;
	}

	if (!isDisabled && to) {
		return (
			<Link to={to}>
				<button css={styles}>
					<img src={iconUrl} width={24} height={24} alt={iconAlt} />
					<span>{label}</span>
				</button>
			</Link>
		);
	}

	if (tag === 'button') {
		return (
			<button css={[styles]} onClick={isDisabled ? null : onClick}>
				{iconUrl && <img src={iconUrl} width={24} height={24} alt={iconAlt} />}
				{label && <span>{label}</span>}
			</button>
		);
	}

	return (
		<div css={[styles]} onClick={isDisabled ? null : onClick}>
			{iconUrl && <img src={iconUrl} width={24} height={24} alt={iconAlt} />}
			{label && <span>{label}</span>}
		</div>
	);
}
