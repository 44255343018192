/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';

import { forwardRef } from 'react';
import { useRouteMatch } from 'react-router';

import Flex from '../../box-model/Flex';

import {
	SideMenuRoomButton,
	SideMenuClosetButton,
	SideMenuBoxesButton,
	SideMenuSaveButton
} from './SideMenuIconButton';

const Separator = styled.div`
	border: 0.5px solid #eeeeee;

	margin-left: 5px;
	margin-right: 5px;
	width: 61px;
`;

const SideMenu = forwardRef(({ onChangePaneButtonClick, getTextContent }, ref) => {
	const matchesRoomPage = useRouteMatch('/aanpassen/de-kamer');
	const matchesClosetPage = useRouteMatch('/aanpassen/de-kast');
	const matchesBoxesPage = useRouteMatch('/aanpassen/boxen');
	const matchesSavePage = useRouteMatch('/aanpassen/opslaan');

	return (
		<Flex
			flexDirection="column"
			justifyContent="space-between"
			width="71px"
			height="323px"
			borderRadius="5px"
			boxShadow="0 3px 5px 0 rgba(0, 0, 0, 0.08)"
			backgroundColor="rgba(255, 255, 255, 0.9)"
			paddingTop="14px"
			paddingBottom="14px"
			marginLeft="-30px"
			ref={ref}
		>
			<SideMenuRoomButton
				getTextContent={getTextContent}
				isSelected={matchesRoomPage}
				onClick={() => onChangePaneButtonClick('/de-kamer')}
			/>

			<Separator />

			<SideMenuClosetButton
				getTextContent={getTextContent}
				isSelected={matchesClosetPage}
				onClick={() => onChangePaneButtonClick('/de-kast')}
			/>

			<Separator />

			<SideMenuBoxesButton
				getTextContent={getTextContent}
				isSelected={matchesBoxesPage}
				onClick={() => onChangePaneButtonClick('/boxen')}
			/>

			<Separator />

			<SideMenuSaveButton
				getTextContent={getTextContent}
				isSelected={matchesSavePage}
				onClick={() => onChangePaneButtonClick('/opslaan')}
			/>
		</Flex>
	);
});

export default SideMenu;
