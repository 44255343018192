import styled from '@emotion/styled/macro';

import React from 'react';

import Helmet from 'react-helmet';

import MobileBackgroundContainer from '../../background-container/MobileBackgroundContainer';

import Flex from '../../box-model/Flex';
import HeaderBar from '../../header-bar/HeaderBar';

import ContentContainer from '../ContentContainer';

const Heading = styled.h1`
	font-family: 'Pacifico';
	font-size: 42px;
	line-height: 0.71;
	text-align: center;
	color: var(--purple-brown);
	margin: -75px 0 25px 0;
`;

const Paragraph = styled.p`
	width: 320px;
	font-family: 'Roboto';
	font-size: 20px;
	font-weight: 300;
	line-height: 1.5;
	text-align: center;
	color: var(--warm-grey);
	margin: 0;
	white-space: pre-wrap;
`;

export default function Mobile({ getTextContent }) {
	const kleinSchermTitel = getTextContent('kleinSchermTitel');
	const kleinSchermTekst = getTextContent('kleinSchermTekst');

	return (
		<Flex flex="1" flexDirection="column">
			<HeaderBar getTextContent={getTextContent} isOnMobile />

			<Helmet>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, shrink-to-fit=yes"
				/>
			</Helmet>

			<Flex flex="1" flexDirection="column">
				<MobileBackgroundContainer />

				<ContentContainer
					alignItems="center"
					flexDirection="column"
					justifyContent="center"
				>
					<Heading>{kleinSchermTitel}</Heading>

					<Paragraph>{kleinSchermTekst}</Paragraph>
				</ContentContainer>
			</Flex>
		</Flex>
	);
}
