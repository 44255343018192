/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import display from '../../../box-model/display';

import HorizontalPlank from './HorizontalPlank';

const styles = css`
	${display('flex')}

	flex: 1;
`;

export default function Shelve({ thickness, textureUrl }) {
	return (
		<div css={styles}>
			<HorizontalPlank thickness={thickness} textureUrl={textureUrl} />
		</div>
	);
}
