/** @jsx jsx */
import { css, jsx } from '@emotion/core';

const buttonStyles = css`
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: flex-start;

	height: 40px;

	outline: none;
	border: none;
	border-radius: 5px;
	padding-left: 10px;
	padding-right: 15px;

	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 500;
	line-height: 1.88;
	color: #ffffff;
	z-index: 1;

	cursor: pointer;

	transition: background-color 0.2s ease-out, border-color 0.2s ease-out, color 0.2s ease-out;

	svg {
		margin-right: 4px;
	}

	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);

	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
`;

const whiteButtonStyles = css`
	${buttonStyles};

	background-color: #ffffff;
	color: var(--pastel-red);

	:hover {
		background-color: var(--pastel-red);
		color: #ffffff;
	}
`;

const regularButtonStyles = css`
	${buttonStyles};

	background-color: var(--pastel-red);
	color: #ffffff;

	:hover {
		background-color: #e33131;
	}
`;

export default function ShoppingCartButton({
	isDisabled = false,
	isRegular = false,
	label,
	onClick
}) {
	return (
		<button
			css={isRegular ? regularButtonStyles : whiteButtonStyles}
			style={{
				pointerEvents: isDisabled ? 'none' : 'auto',
				cursor: isDisabled ? 'pointer' : 'hand'
			}}
			onClick={isDisabled ? null : onClick}
		>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<path fill="none" d="M0 0h24v24H0z" />
				<path
					fill="currentColor"
					d="M15.55 13a1.991 1.991 0 0 0 1.75-1.03l3.58-6.49A1 1 0 0 0 20.01 4H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44A2 2 0 0 0 7 17h12v-2H7l1.1-2zM6.16 6h12.15l-2.76 5H8.53zM7 18a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm10 0a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"
				/>
			</svg>

			<span>{label}</span>
		</button>
	);
}
