/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';

import currency from 'currency.js';

import Block from '../../box-model/Block';
import Flex from '../../box-model/Flex';

import ProductImage from '../../product/ProductImage';

import closeIconUrl from './close-icon.svg';
import arrowRightIconUrl from './arrow-right-icon.svg';

const modalStyles = css`
	display: flex;
	flex-direction: column;
	align-self: center;

	position: absolute;
	left: 129px;

	width: 852px;
	height: 483px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 5px;

	padding-top: 14px;
	padding-bottom: 14px;
	padding-left: 12px;
	padding-right: 12px;

	z-index: 1;
`;

const Heading = styled.h2`
	font-family: 'Pacifico';
	font-size: 36px;
	font-weight: normal;
	line-height: 0.83;
	color: var(--purple-brown);
	margin-top: 0;
	margin-bottom: 0;
`;

const SubHeading = styled.p`
	font-family: 'Roboto';
	font-size: 20px;
	font-weight: 500;
	line-height: 1.5;
	color: var(--pastel-red);
	padding: 0;
	margin: 20px 0 10px 0;
`;

const ModalCloseButton = styled.div`
	position: absolute;
	top: -10px;
	right: -10px;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 39px;
	height: 39px;
	border-radius: 5px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
	background-color: var(--pastel-red);
	cursor: pointer;

	transition: background-color 0.2s ease-in-out;
	:hover {
		background-color: #e33131;
	}
`;

const Intro = styled.h3`
	font-family: 'Roboto';
	font-size: 18px;
	font-weight: 300;
	font-style: italic;
	line-height: 1.67;
	color: var(--dark-mauve);
	margin: 0;
	padding: 0;
`;

const Description = styled.div`
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 300;
	line-height: 1.25;
	text-align: left;
	color: var(--warm-grey);
	width: 527px;
	overflow-x: hidden;
	overflow-y: visible;

	p {
		color: inherit;
		margin: 0 0 24px 0;
		padding: 0;
	}
`;

const Specs = styled.h3`
	font-family: 'Roboto';
	font-size: 20px;
	line-height: 1.5;
	font-weight: normal;
	color: var(--dark-mauve);
	padding: 0;
	margin: 0 0 10px 0;
`;

const SpecHeading = styled.div`
	font-family: 'Roboto';
	font-size: 14px;
	font-weight: bold;
	line-height: 1.43;
	color: var(--purple-brown);
`;
const Spec = styled.div`
	font-family: 'Roboto';
	font-size: 14px;
	line-height: 1.43;
	color: var(--purple-brown);
`;

const MoreInfoHeading = styled.div`
	font-family: 'Roboto';
	font-size: 14px;
	font-weight: 500;
	line-height: 1.29;
	text-align: left;
	color: var(--warm-grey);
`;
const MoreInfoLink = styled.a`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-family: 'Roboto';
	font-size: 14px;
	line-height: 1.29;
	text-align: left;
	color: var(--warm-grey);
	cursor: pointer;

	transition: color 0.2s ease-in-out;
	:hover {
		color: var(--pastel-red);
	}
`;

const euro = value => currency(value, { separator: '.', symbol: '€', decimal: ',' }).format(true);

export default function InfoModal({ getTextContent, onCloseModalButtonClick, product }) {
	const infoModalProductInformatie =
		getTextContent('infoModalProductInformatie') || 'Productinformatie';
	const infoModalSpecificaties = getTextContent('infoModalSpecificaties') || 'Specificaties';
	const infoModalKleur = getTextContent('infoModalKleur') || 'Kleur';
	const infoModalMateriaal = getTextContent('infoModalMateriaal') || 'Materiaal';
	const infoModalBinnenmaat = getTextContent('infoModalBinnenmaat') || 'Binnenmaat';
	const infoModalBuitenmaat = getTextContent('infoModalBuitenmaat') || 'Buitenmaat';
	const infoModalMeerInformatie = getTextContent('infoModalMeerInformatie') || 'Meer informatie';
	const infoModalBekijkKenmerken =
		getTextContent('infoModalBekijkKenmerken') || 'Bekijk alle kenmerken';

	return (
		<div css={modalStyles}>
			<ModalCloseButton onClick={onCloseModalButtonClick}>
				<img src={closeIconUrl} width={24} height={24} alt="close icon" />
			</ModalCloseButton>

			<Heading marginBottom="24px">{infoModalProductInformatie}</Heading>
			<SubHeading>
				{`${product.seriesLabel} ${product.volume}L ${product.colourLabel}`}{' '}
				<span
					css={css`
						color: var(--purple-brown);
					`}
				>{`${euro(product.price)}`}</span>
			</SubHeading>

			<Intro>{product.intro}</Intro>

			<Flex flex="1" flexDirection="row" justifyContent="space-between">
				<Description dangerouslySetInnerHTML={{ __html: product.description }} />

				<Flex flex="1" alignItems="center" justifyContent="center">
					<ProductImage product={product} isFullSize />
				</Flex>
			</Flex>

			<Specs>{infoModalSpecificaties}</Specs>

			<Flex flex="none" flexDirection="row" justifyContent="space-between">
				<Flex flex="none" flexDirection="row" spaceHorizontal="45px">
					<Block flex="none" width="130px" spaceVertical="35px">
						<Block>
							<SpecHeading>{infoModalKleur}</SpecHeading>
							<Spec>{product.colourLabel}</Spec>
						</Block>
						<Block>
							<SpecHeading>{infoModalMateriaal}</SpecHeading>
							<Spec>{product.material}</Spec>
						</Block>
					</Block>
					<Block flex="none" width="130px" spaceVertical="35px">
						<Block>
							<SpecHeading>{infoModalBuitenmaat}</SpecHeading>
							<Spec>
								{product.widthInCm}x{product.heightInCm}x{product.depthInCm}
								cm
							</Spec>
						</Block>
						<Block>
							<SpecHeading>{infoModalBinnenmaat}</SpecHeading>
							<Spec>
								{product.innerWidthInCm}x{product.innerHeightInCm}x
								{product.innerDepthInCm}
								cm
							</Spec>
						</Block>
					</Block>
				</Flex>

				<Block flex="none" alignSelf="flex-end">
					<MoreInfoHeading>{infoModalMeerInformatie}</MoreInfoHeading>
					<MoreInfoLink href={product.externalUrl} target="_blank">
						{infoModalBekijkKenmerken}{' '}
						<img
							src={arrowRightIconUrl}
							alt="arrow right icon"
							width="24px"
							height="24px"
						/>
					</MoreInfoLink>
				</Block>
			</Flex>
		</div>
	);
}
