/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled/macro';

import { useMemo, useRef } from 'react';

import Block from '../box-model/Block';
import Flex from '../box-model/Flex';

import Product from './Product';

const InfoMessage = styled.p`
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 300;
	line-height: 1.25;
	color: var(--dark-mauve);
	margin: 0;
`;

const tableStyles = css`
	border: 0;
	width: 100%;
`;

const tableCellStyles = css`
	width: 50%;
	vertical-align: bottom;
	padding-bottom: 10px;

	> * {
		margin: 0 auto;
	}
`;

const ProductDimensions = styled.div`
	text-align: center;
	font-family: 'Roboto';
	font-size: 10px;
	line-height: 2;
	color: rgba(112, 112, 112, 0.5);
`;

// const euro = value => currency(value, { separator: '.', symbol: '€', decimal: ',' }).format(true);

const PIXELS_PER_CM = 2.25;

export default function ProductSelector({
	currentCloset,
	currentColor,
	currentSeries,
	getTextContent,
	deBoxenBezigMetLaden,
	deBoxenGeenProducten,
	deBoxenKiesAndere,
	onBeginDrag,
	openInfoModal,
	products,
	tooltipPortalRef
}) {
	const productsAsTable = useMemo(
		() =>
			products.reduce((productsTable, product, index) => {
				if (index % 2 === 0) {
					productsTable.push([product]);
				} else {
					productsTable[productsTable.length - 1].push(product);
				}
				return productsTable;
			}, []),
		[products]
	);

	let closetHeightMinusShelves = currentCloset.height;
	closetHeightMinusShelves -= 12 / PIXELS_PER_CM;
	closetHeightMinusShelves -= 12 / PIXELS_PER_CM;
	closetHeightMinusShelves -= currentCloset.shelveThickness * (currentCloset.totalShelves - 1);
	// console.log('closetHeightMinusShelves final', closetHeightMinusShelves);
	let shelveHeight = closetHeightMinusShelves / currentCloset.totalShelves;
	// console.log('shelveHeight', shelveHeight);

	const tooltipScrollContainerRef = useRef();

	return (
		<Block maxHeight="350px" overflowY="auto" ref={tooltipScrollContainerRef}>
			{products.length === 0 &&
				(!currentSeries || !currentColor || !currentSeries.name || !currentColor.name) && (
					<InfoMessage>{deBoxenBezigMetLaden}</InfoMessage>
				)}

			{products.length === 0 && currentSeries.name && currentColor && currentColor.name && (
				<InfoMessage>
					{deBoxenGeenProducten}
					<br />
					{deBoxenKiesAndere}
				</InfoMessage>
			)}

			{products.length > 0 && (
				<table css={tableStyles}>
					<tbody>
						{productsAsTable.map((tableRow, index) => (
							<tr key={index}>
								{tableRow.map(product => (
									<td key={product.guid} css={tableCellStyles}>
										<Flex flexDirection="column" alignItems="center">
											<Product
												getTextContent={getTextContent}
												closetDepth={currentCloset.depth * PIXELS_PER_CM}
												closetWidth={
													(currentCloset.width - 24 / PIXELS_PER_CM) *
													PIXELS_PER_CM
												}
												shelveHeight={shelveHeight * PIXELS_PER_CM}
												onBeginDrag={onBeginDrag}
												openInfoModal={openInfoModal}
												product={product}
												tooltipPortalRef={tooltipPortalRef}
												tooltipScrollContainerRef={
													tooltipScrollContainerRef
												}
											/>

											<ProductDimensions>
												{product.volume}L w: {product.widthInCm} x h:{' '}
												{product.heightInCm} x d: {product.depthInCm}cm{' '}
											</ProductDimensions>
										</Flex>
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			)}
		</Block>
	);
}
