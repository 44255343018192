/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';

import { Fragment, useEffect, useState, useCallback } from 'react';
import { SliderPicker } from 'react-color';

import Flex from '../../box-model/Flex';

import colorPickerIconUrl from './room/color-picker-icon.svg';
// import zoomInIconUrl from './room/zoom-in-icon-white.svg';

import Pane from './Pane';

const Heading3 = styled.h3`
	font-family: 'Roboto';
	font-size: 14px;
	font-weight: bold;
	line-height: 1.43;
	text-align: left;
	margin-bottom: 0;
	color: var(--purple-brown);
`;

const ColorSquare = styled.div`
	flex: none;
	width: 30px;
	height: 30px;
	box-sizing: border-box;
	border: ${props =>
		props.isSelected ? 'solid 2px var(--pastel-red)' : 'solid 1px rgba(112, 112, 112, 0.5)'};
	background-color: ${props => props.color};
	cursor: pointer;

	transition: border 0.2s ease-in-out;
	:hover {
		border: solid 2px var(--pastel-red);
	}
`;

const ColorPicker = styled.div`
	flex: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	box-sizing: border-box;
	border: ${props =>
		props.isSelected ? 'solid 2px var(--pastel-red)' : 'solid 1px rgba(112, 112, 112, 0.5)'};
	background-color: ${props => props.color || '#ffffff'};
	cursor: pointer;

	transition: border 0.2s ease-in-out;
	:hover {
		border: solid 2px var(--pastel-red);
	}
`;

const BackgroundThumbnail = styled.div`
	flex: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 40px;
	box-sizing: border-box;
	border: ${props =>
		props.isSelected ? 'solid 2px var(--pastel-red)' : 'solid 1px rgba(112, 112, 112, 0.5)'};
	background: url(${props => props.src});
	background-size: cover;
	cursor: pointer;

	transition: border 0.2s ease-in-out;
	:hover {
		border: solid 2px var(--pastel-red);
	}
`;

export default function RoomPane({
	auth,
	currentLocale,
	currentCloset,
	getTextContent,
	onClosePaneButtonClick,
	setBackgroundToColor,
	setBackgroundToPhoto
}) {
	const [backgroundOptions, setBackgroundOptions] = useState([]);

	const [colorPickerColor, setColorPickerColor] = useState(currentCloset.colour || '#ffffff');
	const [showColorPicker, setShowColorPicker] = useState(false);
	const toggleShowColorPicker = useCallback(
		() => setShowColorPicker(showColorPicker => !showColorPicker),
		[]
	);
	const handleColorPickerChange = useCallback(
		color => {
			// console.log('COLOR', color.hex);
			if (color.hex && color.hex !== '#ffffff') {
				setColorPickerColor(color.hex);
				setBackgroundToColor(color.hex);
			}
		},
		[setBackgroundToColor]
	);

	useEffect(() => {
		auth.fetch(`/background?languageCode=${currentLocale}`)
			.then(response => response.json())
			.then(backgrounds => {
				// console.log('GET BACKGROUNDS RESPONSE JSON', backgrounds);

				if (backgrounds && Array.isArray(backgrounds)) {
					setBackgroundOptions(backgrounds);
				} else {
					// console.log('GET BACKGROUNDS UNEXPECTED RESPONSE JSON', backgrounds);
				}
			})
			.catch(error => {
				// console.log('GET BACKGROUNDS ERROR', error);
			});
	}, [auth, currentLocale]);

	const deKamerTitel = getTextContent('deKamerTitel');
	const deKamerSubtitel = getTextContent('deKamerSubtitel');
	const deKamerFoto = getTextContent('deKamerFoto');
	const deKamerKleur = getTextContent('deKamerKleur');
	const deKamerKiesKleur = getTextContent('deKamerKiesKleur') || 'Kies een eigen kleur';

	return (
		<Fragment>
			<Pane
				heading={deKamerTitel}
				onClosePaneButtonClick={onClosePaneButtonClick}
				subHeading={deKamerSubtitel}
			>
				<Heading3>{deKamerFoto}</Heading3>

				<Flex
					flex="none"
					flexDirection="row"
					spaceHorizontal="15px"
					spaceVertical="15px"
					flexWrap="wrap"
				>
					{backgroundOptions.map(backgroundOption => (
						<BackgroundThumbnail
							key={backgroundOption.guid}
							src={backgroundOption.image}
							isSelected={currentCloset.backgroundGuid === backgroundOption.guid}
							onClick={() => {
								setShowColorPicker(false);
								setBackgroundToPhoto(backgroundOption);
							}}
						>
							{/* <img src={zoomInIconUrl} alt="zoom in icon" width={24} height={24} /> */}
						</BackgroundThumbnail>
					))}
				</Flex>

				<Heading3>{deKamerKleur}</Heading3>

				<Flex flex="none" flexDirection="row" spaceHorizontal="15px" flexWrap="wrap">
					<ColorSquare
						color="#ffffff"
						isSelected={currentCloset.colour === '#ffffff'}
						onClick={() => {
							setShowColorPicker(false);
							setBackgroundToColor('#ffffff');
						}}
					/>
					<ColorSquare
						color="#ebebeb"
						isSelected={currentCloset.colour === '#ebebeb'}
						onClick={() => {
							setShowColorPicker(false);
							setBackgroundToColor('#ebebeb');
						}}
					/>
					<ColorSquare
						color="#bee2d7"
						isSelected={currentCloset.colour === '#bee2d7'}
						onClick={() => {
							setShowColorPicker(false);
							setBackgroundToColor('#bee2d7');
						}}
					/>
					<ColorSquare
						color="#e2dfc8"
						isSelected={currentCloset.colour === '#e2dfc8'}
						onClick={() => {
							setShowColorPicker(false);
							setBackgroundToColor('#e2dfc8');
						}}
					/>
					<ColorSquare
						color="#5e6b5d"
						isSelected={currentCloset.colour === '#5e6b5d'}
						onClick={() => {
							setShowColorPicker(false);
							setBackgroundToColor('#5e6b5d');
						}}
					/>
					<ColorSquare
						color="#5f606a"
						isSelected={currentCloset.colour === '#5f606a'}
						onClick={() => {
							setShowColorPicker(false);
							setBackgroundToColor('#5f606a');
						}}
					/>

					<ColorPicker
						color={colorPickerColor}
						onClick={toggleShowColorPicker}
						isSelected={currentCloset.colour === colorPickerColor}
					>
						<img src={colorPickerIconUrl} alt="color picker icon" />
					</ColorPicker>
				</Flex>
			</Pane>

			{showColorPicker && (
				<Pane onClosePaneButtonClick={toggleShowColorPicker}>
					<Heading3>{deKamerKiesKleur}</Heading3>

					<SliderPicker color={colorPickerColor} onChange={handleColorPickerChange} />
				</Pane>
			)}
		</Fragment>
	);
}
