/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled/macro';

import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Flex from '../../box-model/Flex';

import Button from '../../button/Button';
import BackTextLink from '../../button/BackTextLink';

import SingleLineInput from '../../input/SingleLineInput';

import Heading2 from '../../typography/Heading2';

import ContentContainer from '../ContentContainer';

import sendIconUrl from './send-icon.svg';

const Paragraph = styled.p`
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 300;
	line-height: 1.25;
	color: ${props => (props.error ? 'var(--pastel-red)' : 'var(--warm-grey)')};
	text-align: center;
	margin-top: ${props => props.marginTop || '0px'};
	margin-bottom: 25px;
	white-space: pre-wrap;
`;

const Form = Flex.withComponent('form');

export default function PasswordForgottenPage({ getTextContent, onSubmit }) {
	const location = useLocation();
	const next =
		location && location.state && location.state.next ? location.state.next : '/mijn-kasten';

	const [error, setError] = useState(false);

	const [email, setEmail] = useState('');

	const handleEmailChange = useCallback(value => {
		setError(null);
		setEmail(value);
	}, []);

	const onSubmitButtonClick = useCallback(
		event => {
			event.preventDefault();
			onSubmit({ email }, next).then(error => {
				if (error && error.result === 'NoUserFound') {
					// console.log('error', error);
					setError(error);
				}
			});
		},
		[email, next, onSubmit]
	);

	const nieuwWachtwoordTitel = getTextContent('nieuwWachtwoordTitel');
	const nieuwWachtwoordTekst = getTextContent('nieuwWachtwoordTekst');
	const inloggenEmailadres = getTextContent('inloggenEmailadres');
	const terug = getTextContent('terug');
	const versturen = getTextContent('versturen');
	const emailOnjuistOfOnbekend = getTextContent('emailOnjuistOfOnbekend');

	return (
		<ContentContainer>
			<Flex width={530} flexDirection="column" alignItems="center">
				<Heading2>{nieuwWachtwoordTitel}</Heading2>

				<Flex flexDirection="column" alignItems="center" width={410}>
					<Paragraph>{nieuwWachtwoordTekst}</Paragraph>

					<Form
						flexDirection="column"
						spaceVertical="24px"
						alignItems="stretch"
						width={350}
					>
						<SingleLineInput
							placeholder={inloggenEmailadres}
							type="email"
							onChange={handleEmailChange}
							value={email}
							isValid={value => value && value.includes('@') && value.includes('.')}
						/>

						{error && (
							<Paragraph marginTop="0px" error>
								{emailOnjuistOfOnbekend}
							</Paragraph>
						)}

						<Flex
							alignSelf="stretch"
							alignItems="stretch"
							flex="1"
							flexDirection="row"
							justifyContent="space-between"
						>
							<BackTextLink label={terug} />

							<Button
								label={versturen}
								iconAlt="send icon"
								iconUrl={sendIconUrl}
								isDisabled={!email || !email.includes('@') || !email.includes('.')}
								onClick={onSubmitButtonClick}
								type="primary"
							/>
						</Flex>
					</Form>
				</Flex>
			</Flex>
		</ContentContainer>
	);
}
