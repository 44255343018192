/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';

import { useCallback, useRef, useState, forwardRef, useEffect } from 'react';

import Block from '../../box-model/Block';
import Flex from '../../box-model/Flex';
import Button from '../../button/Button';
import SingleLineInput from '../../input/SingleLineInput';

import copyIconUrl from './save/copy-icon.svg';
import loginIconUrl from './save/login-icon.svg';
import savePdfIconUrl from './save/download-pdf-icon.svg';
import saveIconUrl from './save/save-icon.svg';

import Pane, { SubHeading } from './Pane';
import { useHistory, useRouteMatch } from 'react-router';
import usePrevious from '../../utils/hooks/usePrevious';

const Paragraph = styled.p`
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: ${props => props.fontWeight};
	line-height: 1.25;
	letter-spacing: normal;
	text-align: left;
	color: var(--warm-grey);
	margin-top: ${props => props.marginTop};
	margin-bottom: ${props => props.marginBottom};
	white-space: pre-wrap;
`;

const SavePane = forwardRef(
	(
		{
			currentCloset,
			currentUser,
			generateDownloadablePDF,
			getTextContent,
			onClosePaneButtonClick,
			saveCloset
		},
		ref
	) => {
		const history = useHistory();
		const { url } = useRouteMatch();

		const [inputName, setInputName] = useState(currentCloset.name || '');

		const [isSaved, setIsSaved] = useState(
			currentCloset.guid !== 'NEW' && !currentCloset.isDirty
		);

		const handleSaveButtonClick = useCallback(() => {
			setIsSaved(true);
			saveCloset(inputName);
		}, [inputName, saveCloset]);

		const wasDirty = usePrevious(currentCloset.isDirty);
		useEffect(() => {
			if (currentCloset.isDirty && !wasDirty) {
				setIsSaved(false);
			}
		}, [currentCloset.isDirty, wasDirty]);

		const shareUrlRef = useRef();
		const shareUrl = `${window.location.href.replace(/\/aanpassen\/.*/g, '')}/bekijken?c=${
			currentCloset.guid
		}`;
		const handleCopyButtonClick = useCallback(() => {
			shareUrlRef.current.select();
			shareUrlRef.current.setSelectionRange(0, 99999);

			window.document.execCommand('copy');
		}, []);

		const handleLoginButtonClick = useCallback(() => {
			history.push('/inloggen', { next: `${url}/opslaan` });
		}, [history, url]);
		const handleRegisterButtonClick = useCallback(() => {
			history.push('/registreren', { next: `${url}/opslaan` });
		}, [history, url]);

		const opslaanTitel = getTextContent('opslaanTitel');
		const opslaanSubtitel = getTextContent('opslaanSubtitel');
		const opslaanKastNaamInvoer =
			getTextContent('opslaanKastNaamInvoer') || 'Geef je kast een naam';
		const opslaanAdvies = getTextContent('opslaanAdvies');
		const opslaanOpgeslagen = getTextContent('opslaanOpgeslagen') || 'Je kast is opgeslagen!';
		const opslaanOpgeslagenAdvies =
			getTextContent('opslaanOpgeslagenAdvies') ||
			'Je kunt je opgeslagen kast later eenvoudig terugvinden in je account.';
		const opslaanTekstIngelogd = getTextContent('opslaanTekstIngelogd');
		const buttonInloggen = getTextContent('buttonInloggen');
		const opslaanTekstRegistreren = getTextContent('opslaanTekstRegistreren');
		const buttonRegistreren = getTextContent('buttonRegistreren');
		const buttonOpslaan = getTextContent('buttonOpslaan') || 'Opslaan';
		const kopieren = getTextContent('kopieren');
		const opslaanDelen = getTextContent('opslaanDelen') || 'Delen';
		const opslaanDelenAdvies =
			getTextContent('opslaanDelenAdvies') || 'Gebruik de volgende link om je kast te delen:';
		const opslaanDownloadenPrinten =
			getTextContent('opslaanDownloadenPrinten') || 'Downloaden of printen';
		const opslaanDownloadenPrintenAdvies =
			getTextContent('opslaanDownloadenPrintenAdvies') ||
			'Wil je de kast met een lijst van de gebruikte producten downloaden of printen?';
		const buttonDownloadPDF = getTextContent('buttonDownloadPDF') || 'Download PDF';

		return (
			<Pane
				heading={opslaanTitel}
				onClosePaneButtonClick={onClosePaneButtonClick}
				ref={ref}
				subHeading={!isSaved ? opslaanSubtitel : opslaanOpgeslagen}
			>
				{!isSaved && (
					<Paragraph fontWeight={500} marginTop="0px" marginBottom="25px">
						{opslaanAdvies}
					</Paragraph>
				)}
				{isSaved && (
					<Paragraph fontWeight={300} marginTop="0px" marginBottom="10px">
						{opslaanOpgeslagenAdvies}
					</Paragraph>
				)}

				{!currentUser && (
					<Block spaceVertical="25px">
						<Block>
							<Paragraph fontWeight={300} marginTop="0px" marginBottom="10px">
								{opslaanTekstIngelogd}
							</Paragraph>

							<Button
								iconUrl={loginIconUrl}
								iconAlt="inloggen icoon"
								label={buttonInloggen}
								type="primary"
								onClick={handleLoginButtonClick}
							/>
						</Block>

						<Block>
							<Paragraph fontWeight={300} marginTop="0px" marginBottom="10px">
								{opslaanTekstRegistreren}
							</Paragraph>

							<Button
								iconUrl={loginIconUrl}
								iconAlt="registreren icoon"
								label={buttonRegistreren}
								type="primary"
								onClick={handleRegisterButtonClick}
							/>
						</Block>
					</Block>
				)}

				{currentUser && !isSaved && (
					<Flex flexDirection="column" spaceVertical="14px" alignItems="flex-end">
						<Block flex="1" alignSelf="stretch">
							<SingleLineInput
								type="text"
								onChange={setInputName}
								placeholder={opslaanKastNaamInvoer}
								value={inputName}
							/>
						</Block>

						<Button
							iconUrl={saveIconUrl}
							iconAlt="opslaan icoon"
							label={buttonOpslaan}
							type="primary"
							onClick={handleSaveButtonClick}
						/>
					</Flex>
				)}
				{currentUser && isSaved && (
					<Flex flexDirection="column">
						<SubHeading marginBottom="10px">{opslaanDelen}</SubHeading>

						<Paragraph fontWeight={300} marginTop="0px" marginBottom="15px">
							{opslaanDelenAdvies}
						</Paragraph>

						<Flex
							flex="none"
							flexDirection="row"
							alignSelf="stretch"
							spaceHorizontal="-5px"
						>
							<SingleLineInput
								ref={shareUrlRef}
								type="text"
								readOnly
								value={shareUrl}
							/>

							<Button
								iconUrl={copyIconUrl}
								iconAlt="kopiëren icoon"
								label={kopieren}
								type="secondary"
								onClick={handleCopyButtonClick}
							/>
						</Flex>

						<SubHeading marginBottom="10px">{opslaanDownloadenPrinten}</SubHeading>

						<Paragraph fontWeight={300} marginTop="0px" marginBottom="10px">
							{opslaanDownloadenPrintenAdvies}
						</Paragraph>

						<Button
							iconUrl={savePdfIconUrl}
							iconAlt="downloaden als pdf icoon"
							label={buttonDownloadPDF}
							onClick={() => generateDownloadablePDF(currentCloset.guid)}
							type="secondary"
							zIndex="1"
						/>
					</Flex>
				)}
			</Pane>
		);
	}
);

export default SavePane;
