/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import { useDragLayer } from 'react-dnd';

import ProductImage from './ProductImage';

const styles = css`
	display: block;
	position: absolute;
	pointer-events: none;
	z-index: 100;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	cursor: grabbing;
`;

const innerStyles = css`
	display: block;
`;

export default function ProductDragLayer({ containerRef }) {
	const { position, product, isDragging } = useDragLayer(monitor => ({
		product: monitor.getItem(),
		initialOffset: monitor.getInitialSourceClientOffset(),
		position: monitor.getSourceClientOffset(),
		isDragging: monitor.isDragging()
	}));

	if (!isDragging || window.navigator.userAgent.indexOf('Edge') > -1) {
		return null;
	}

	let x = position && position.x;
	let y = position && position.y;
	if (containerRef && containerRef.current) {
		const boundingClientReact = containerRef.current.getBoundingClientRect();
		x -= boundingClientReact.left;
		y -= boundingClientReact.top;
		y += containerRef.current.scrollTop;
	}

	return (
		<div css={styles} data-product-drag-layer>
			<div
				css={innerStyles}
				style={
					!position || !product || !product.type || product.type === 'DISABLED_PRODUCT'
						? { display: 'none' }
						: { transform: `translate(${x}px, ${y}px)` }
				}
			>
				<ProductImage product={product} />
			</div>
		</div>
	);
}
