/** @jsx jsx */
import { css, jsx } from '@emotion/core';

const styles = css`
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 500;
	text-align: center;

	cursor: pointer;
	margin: 0;
	padding: 0;
	border: none;
	outline: none;

	color: var(--dark-mauve);
	background-color: transparent;

	fill: currentColor;

	transition: color 0.2s ease-in-out;
	:hover {
		color: var(--pastel-red);
	}
`;
const isSelectedStyles = css`
	${styles}
	pointer-events: none;
	color: var(--pastel-red);
`;

const iconStyles = css`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

export function SideMenuRoomButton({ getTextContent, isSelected, onClick }) {
	return (
		<button css={isSelected ? isSelectedStyles : styles} onClick={onClick}>
			<div css={iconStyles}>
				<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
					<path fill="none" d="M0 0h36v36H0z" />
					<path
						fill="currentColor"
						d="M25 5V3.5A1.5 1.5 0 0 0 23.5 2h-18A1.5 1.5 0 0 0 4 3.5v6A1.5 1.5 0 0 0 5.5 11h18A1.5 1.5 0 0 0 25 9.5V8h1.5v6h-15v16.5A1.5 1.5 0 0 0 13 32h3a1.5 1.5 0 0 0 1.5-1.5V17h12V5zm-3 3H7V5h15z"
						transform="translate(2 1)"
					/>
				</svg>
			</div>

			<span>{getTextContent('Menu-Kamer')}</span>
		</button>
	);
}

export function SideMenuClosetButton({ getTextContent, isSelected, onClick }) {
	return (
		<button css={isSelected ? isSelectedStyles : styles} onClick={onClick}>
			<div css={iconStyles}>
				<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
					<path
						fill="currentColor"
						d="M27.8 2H7.4A3.41 3.41 0 0 0 4 5.4v27.2A3.41 3.41 0 0 0 7.4 36h20.4a3.41 3.41 0 0 0 3.4-3.4V5.4A3.41 3.41 0 0 0 27.8 2zm0 10.54h-8.5V5.4h8.5zm-8.5 3.4h8.5v6.8h-8.5zm-3.4 6.8H7.4v-6.8h8.5zm0-17.34v7.14H7.4V5.4zM7.4 26.14h8.5v6.46H7.4zm11.9 6.46v-6.46h8.5v6.46z"
						transform="translate(0.5 -1)"
					/>
				</svg>
			</div>

			<span>{getTextContent('Menu-Kast')}</span>
		</button>
	);
}

export function SideMenuBoxesButton({ getTextContent, isSelected, onClick }) {
	return (
		<button css={isSelected ? isSelectedStyles : styles} onClick={onClick}>
			<div css={iconStyles}>
				<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
					<path fill="none" d="M0 0h42v42H0z" />
					<path
						fill="currentColor"
						d="M33.625 6.85l-2.45-2.975A2.258 2.258 0 0 0 29.25 3h-21a2.9 2.9 0 0 0-2.1.875L3.875 6.85A3.686 3.686 0 0 0 3 9.125V31a3.51 3.51 0 0 0 3.5 3.5H31a3.51 3.51 0 0 0 3.5-3.5V9.125a3.686 3.686 0 0 0-.875-2.275zM8.6 6.5h20.125l1.4 1.75H7.2zM6.5 31V11.75H31V31z"
						transform="translate(0 2)"
					/>
				</svg>
			</div>

			<span>{getTextContent('Menu-Boxen')}</span>
		</button>
	);
}

export function SideMenuSaveButton({ getTextContent, isSelected, onClick }) {
	return (
		<button css={isSelected ? isSelectedStyles : styles} onClick={onClick}>
			<div css={iconStyles}>
				<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
					<path d="M0 0h41v41H0z" fill="none" />
					<path
						d="M30.333 18.375v11.958H6.417V18.375H3v11.958a3.427 3.427 0 0 0 3.417 3.417h23.916a3.427 3.427 0 0 0 3.417-3.417V18.375zm-10.25 1.145l4.425-4.408 2.409 2.409-8.542 8.542-8.542-8.542 2.409-2.409 4.425 4.408V3h3.417z"
						fill="currentColor"
						transform="translate(0 2)"
					/>
				</svg>
			</div>

			<span>{getTextContent('Menu-Opslaan')}</span>
		</button>
	);
}
