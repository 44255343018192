/** @jsx jsx */
import { css, jsx } from '@emotion/core';

const styles = css`
	position: relative;

	display: flex;
	flex-direction: row;
	align-items: center;

	font-family: 'Roboto';
	font-size: 16px;
	line-height: 1.25;

	color: var(--purple-brown);

	input {
		border: none;
		outline: none;
	}

	> :not(:last-child) {
		margin-right: 8px;
	}
`;

export default function RadioButtonInput({ label, group, value, checked, onChange }) {
	return (
		<label css={styles}>
			<input type="radio" name={group} value={value} checked={checked} onChange={onChange} />
			<span>{label}</span>
		</label>
	);
}
