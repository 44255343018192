/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';

import Flex from '../../box-model/Flex';

import avatarIconUrl from './avatar-icon.svg';
import arrowRightIconUrl from './arrow-right-icon.svg';

const UserName = styled.span`
	font-family: Roboto;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.13;
	color: var(--dark-mauve);
	cursor: pointer;
`;

const LogoutLink = styled.a`
	flex: none;
	display: flex;
	flex-direction: row;
	font-family: Roboto;
	font-size: 14px;
	font-weight: normal;
	color: var(--warm-grey);
	align-items: center;
	cursor: pointer;
	text-decoration: none;
	margin-top: -5px;
	margin-right: 2px;

	:hover {
		color: var(--dark-mauve);
		text-decoration: underline;
	}
`;

export default function Avatar({ currentUser, onLogout, onUserNameClick }) {
	return (
		<Flex flex="none" flexDirection="column" alignItems="flex-end">
			<Flex
				flex="none"
				flexDirection="row"
				alignItems="center"
				spaceHorizontal="5px"
				marginRight="5px"
			>
				<img src={avatarIconUrl} alt="gezicht icoon" width="24" height="24" />

				<UserName onClick={onUserNameClick}>
					{currentUser.firstName} {currentUser.lastName}
				</UserName>
			</Flex>

			<LogoutLink onClick={onLogout}>
				<span>Uitloggen</span>

				<img src={arrowRightIconUrl} alt="uitloggen icoon" width="" height="" />
			</LogoutLink>
		</Flex>
	);
}
