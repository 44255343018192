/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';

import { useRouteMatch } from 'react-router-dom';

import Block from '../box-model/Block';

import lightBackgroundUrl from './light-background-3x.jpg';
import brownBackgroundUrl from './brown-background-3x.jpg';
import greyBackgroundUrl from './grey-background-3x.jpg';

const ColoredBackground = styled.div`
	position: relative;
	width: 1110px;
	height: 608px;
	background-color: ${props => props.color};
`;

const Floor = styled.div`
	position: absolute;
	width: 1110px;
	height: 25px;
	bottom: 0;
	opacity: 0.5;
	background-color: #222222;
`;

export default function BackgroundContainer({ backgroundOptions, currentCloset }) {
	const matchesWelcomePage = useRouteMatch({ path: '/', exact: true });
	const matchesLoginPage = useRouteMatch('/inloggen');
	const matchesRegisterPage = useRouteMatch('/registreren');
	const matchesPasswordForgottenPage = useRouteMatch('/wachtwoord-vergeten');
	const matchesSetPasswordPage = useRouteMatch('/wachtwoord-instellen');
	const matchesMyClosetsPage = useRouteMatch('/mijn-kasten');
	const matchesViewClosetPage = useRouteMatch('/bekijken');

	const matchesWizardPage = useRouteMatch('/beginnen');
	const matchesConfiguratorPage = useRouteMatch('/aanpassen');

	const showWelcomePageBackground =
		matchesWelcomePage ||
		matchesLoginPage ||
		matchesRegisterPage ||
		matchesPasswordForgottenPage ||
		matchesSetPasswordPage ||
		matchesMyClosetsPage;

	const showWizardPageBackground = matchesWizardPage;
	const showConfiguratorPageBackground =
		matchesConfiguratorPage && !currentCloset.colour && !currentCloset.backgroundGuid;

	const currentClosetBackgroundOption =
		currentCloset.backgroundGuid &&
		backgroundOptions.find(
			backgroundOption => backgroundOption.guid === currentCloset.backgroundGuid
		);

	return (
		<Block width="1110px" height="608px">
			{showWelcomePageBackground && (
				<img
					css={css(`display: block;`)}
					src={greyBackgroundUrl}
					width={1110}
					height={608}
					alt="pagina achtergrond"
				/>
			)}
			{showWizardPageBackground && (
				<img
					css={css(`display: block;`)}
					src={lightBackgroundUrl}
					width={1110}
					height={608}
					alt="pagina achtergrond"
				/>
			)}
			{showConfiguratorPageBackground && (
				<img
					css={css(`display: block;`)}
					src={brownBackgroundUrl}
					width={1110}
					height={608}
					alt="pagina achtergrond"
				/>
			)}

			{!showWelcomePageBackground &&
				!showWizardPageBackground &&
				(matchesViewClosetPage || matchesConfiguratorPage) &&
				currentClosetBackgroundOption && (
					<img
						css={css(`display: block;`)}
						src={currentClosetBackgroundOption.image}
						width={1110}
						height={608}
						alt="pagina achtergrond"
					/>
				)}
			{!showWelcomePageBackground &&
				!showWizardPageBackground &&
				(matchesViewClosetPage || matchesConfiguratorPage) &&
				currentCloset.colour && (
					<ColoredBackground color={currentCloset.colour}>
						<Floor />
					</ColoredBackground>
				)}
		</Block>
	);
}
