import { useCallback, useState } from 'react';

/** @jsx jsx */
import { jsx } from '@emotion/core';

import { useHistory, useRouteMatch } from 'react-router-dom';

import Flex from '../../box-model/Flex';
import ShelvingUnit from './shelving-unit/ShelvingUnit';

import ContentContainer from '../ContentContainer';

import SideMenu from './SideMenu';

import RoomPane from './RoomPane';
import ClosetPane from './ClosetPane';
import BoxesPane from './BoxesPane';
import SavePane from './SavePane';

import InfoModal from './InfoModal';
import ShoppingCartPane from './ShoppingCartPane';

const PIXELS_PER_CM = 2.25;

export default function ConfiguratorPage({
	auth,
	currentLocale,
	currentCloset,
	currentUser,
	deleteProduct,
	generateDownloadablePDF,
	getTextContent,
	hideShoppingCartPane,
	isClosetPaneDisabled,
	isColorsPaneVisible,
	isShoppingCartPaneVisible,
	materialOptions,
	onBeginDrag,
	onClearButtonClick,
	onDropProduct,
	redInfoPopupIsVisible,
	rotateProduct,
	setCurrentCloset,
	saveCloset,
	savePaneRef,
	sideMenuRef,
	setBackgroundToColor,
	setBackgroundToPhoto,
	setIsColorsPaneVisible,
	setRedInfoPopupIsVisible
}) {
	const { url } = useRouteMatch();
	const history = useHistory();

	const matchesRoomPane = useRouteMatch(`${url}/de-kamer`);
	const matchesClosetPane = useRouteMatch(`${url}/de-kast`);
	const matchesBoxesPane = useRouteMatch(`${url}/boxen`);
	const matchesSavePane = useRouteMatch(`${url}/opslaan`);

	const matchesInfoModal = useRouteMatch(`${url}/product`);

	const gotoPane = useCallback(paneUrl => history.push(`${url}${paneUrl}`), [history, url]);

	const closePane = useCallback(() => history.push(url), [history, url]);

	const [infoModalProduct, setInfoModalProduct] = useState(null);
	const openInfoModal = useCallback(
		product => {
			history.push(`${url}/product`);

			setInfoModalProduct(product);
		},
		[history, url]
	);
	const closeInfoModal = useCallback(() => history.goBack(), [history]);

	const handleSaveCloset = useCallback(
		closetName => {
			saveCloset(closetName);
		},
		[saveCloset]
	);

	const currentClosetMaterialOption = materialOptions.find(
		materialOption => materialOption.guid === currentCloset.closetTextureGuid
	);

	return (
		<ContentContainer
			alignItems="stretch"
			justifyContent="flex-start"
			flexDirection="row"
			onClick={hideShoppingCartPane}
		>
			<Flex
				alignItems="center"
				justifyContent="flex-start"
				flexDirection="row"
				flex="1"
				spaceHorizontal="10px"
			>
				<SideMenu
					onChangePaneButtonClick={gotoPane}
					getTextContent={getTextContent}
					ref={sideMenuRef}
				/>

				{matchesRoomPane && (
					<RoomPane
						auth={auth}
						currentLocale={currentLocale}
						currentCloset={currentCloset}
						getTextContent={getTextContent}
						onClosePaneButtonClick={closePane}
						setBackgroundToColor={setBackgroundToColor}
						setBackgroundToPhoto={setBackgroundToPhoto}
					/>
				)}

				{matchesClosetPane && (
					<ClosetPane
						auth={auth}
						currentLocale={currentLocale}
						currentCloset={currentCloset}
						getTextContent={getTextContent}
						isDisabled={isClosetPaneDisabled}
						onClearButtonClick={onClearButtonClick}
						onClosePaneButtonClick={closePane}
						setCurrentCloset={setCurrentCloset}
					/>
				)}

				{matchesBoxesPane && (
					<BoxesPane
						auth={auth}
						currentLocale={currentLocale}
						currentCloset={currentCloset}
						getTextContent={getTextContent}
						isColorsPaneVisible={isColorsPaneVisible}
						redInfoPopupIsVisible={redInfoPopupIsVisible}
						setIsColorsPaneVisible={setIsColorsPaneVisible}
						setRedInfoPopupIsVisible={setRedInfoPopupIsVisible}
						onBeginDrag={onBeginDrag}
						onClosePaneButtonClick={closePane}
						openInfoModal={openInfoModal}
					/>
				)}

				{matchesSavePane && (
					<SavePane
						currentCloset={currentCloset}
						currentUser={currentUser}
						generateDownloadablePDF={generateDownloadablePDF}
						getTextContent={getTextContent}
						onClosePaneButtonClick={closePane}
						ref={savePaneRef}
						saveCloset={handleSaveCloset}
					/>
				)}

				<Flex flex="1" alignItems="flex-end" alignSelf="stretch" justifyContent="flex-end">
					<ShelvingUnit
						getTextContent={getTextContent}
						deleteProduct={deleteProduct}
						rotateProduct={rotateProduct}
						onBeginDrag={onBeginDrag}
						onDropProduct={onDropProduct}
						openInfoModal={openInfoModal}
						placedProducts={currentCloset.boxes}
						shelveThickness={currentCloset.shelveThickness * PIXELS_PER_CM}
						textureUrl={
							currentClosetMaterialOption ? currentClosetMaterialOption.image : null
						}
						totalShelves={currentCloset.totalShelves}
						width={currentCloset.width * PIXELS_PER_CM}
						height={currentCloset.height * PIXELS_PER_CM}
						depth={currentCloset.depth * PIXELS_PER_CM}
					/>
				</Flex>
			</Flex>

			{matchesInfoModal && infoModalProduct && (
				<InfoModal
					getTextContent={getTextContent}
					onCloseModalButtonClick={closeInfoModal}
					product={infoModalProduct}
				/>
			)}

			{isShoppingCartPaneVisible && (
				<ShoppingCartPane
					getTextContent={getTextContent}
					currentLocale={currentLocale}
					onClosePaneButtonClick={hideShoppingCartPane}
					placedProducts={currentCloset ? currentCloset.boxes : []}
				/>
			)}
		</ContentContainer>
	);
}
