/** @jsx jsx */
import { jsx } from '@emotion/core';

import { useCallback, useRef, forwardRef } from 'react';

import { useDrop } from 'react-dnd';

import Flex from '../../../box-model/Flex';

import PlacedProduct from '../../../product/PlacedProduct';

import HorizontalPlank from './HorizontalPlank';
import Shelve from './Shelve';
import VerticalPlank from './VerticalPlank';

const OUTER_PLANKS_THICKNESS = 12;
const PIXELS_PER_CM = 2.25;
// const OUTER_PLANKS_THICKNESS_IN_CM = OUTER_PLANKS_THICKNESS / PIXELS_PER_CM;

const findCollidingProductWithItem = (otherProducts, item, itemX, itemY) =>
	otherProducts.reduce((collidingProduct, otherProduct) => {
		if (collidingProduct) {
			return collidingProduct;
		}

		const hasOverlapX =
			itemX + item.getSize(item) > otherProduct.x &&
			itemX < otherProduct.x + otherProduct.getSize(otherProduct);
		const hasOverlapY =
			itemY + item.height > otherProduct.y && itemY < otherProduct.y + otherProduct.height;

		if (hasOverlapX && hasOverlapY) {
			return otherProduct;
		}

		return collidingProduct;
	}, null);

const findCollidingProductAndOverlapWithItem = (otherProducts, item, itemX, itemY) =>
	otherProducts.reduce(
		({ collidingProduct, overlap }, otherProduct) => {
			if (collidingProduct) {
				return { collidingProduct, overlap };
			}

			const hasOverlapX =
				itemX + item.getSize(item) > otherProduct.x &&
				itemX < otherProduct.x + otherProduct.getSize(otherProduct);
			const hasOverlapY =
				itemY + item.height > otherProduct.y &&
				itemY < otherProduct.y + otherProduct.height;

			if (hasOverlapX && hasOverlapY) {
				const itemCenterX = itemX + item.getSize(item) / 2;
				const itemCenterY = itemY + item.height / 2;

				const otherProductCenterX = otherProduct.x + otherProduct.getSize(otherProduct) / 2;
				const otherProductCenterY = otherProduct.y + otherProduct.height / 2;

				const distanceX = Math.abs(otherProductCenterX - itemCenterX);
				const maxDistanceX =
					item.getSize(item) / 2 + otherProduct.getSize(otherProduct) / 2;
				const factorDistanceX = distanceX / maxDistanceX;

				const distanceY = Math.abs(otherProductCenterY - itemCenterY);
				const maxDistanceY = item.height / 2 + otherProduct.height / 2;
				const factorDistanceY = distanceY / maxDistanceY;

				let leftPriority = itemCenterX < otherProductCenterX ? 2 : -1;
				let rightPriority = itemCenterX >= otherProductCenterX ? 2 : -1;
				let topPriority = itemCenterY <= otherProductCenterY ? 2 : -1;
				let bottomPriority = itemCenterY > otherProductCenterY ? 2 : -1;

				if (factorDistanceX > factorDistanceY) {
					if (topPriority === 2) {
						// 2 > 1
						topPriority -= 1;
						// -1 > 0
						bottomPriority += 1;
					} else {
						// 2 > 1
						bottomPriority -= 1;
						// -1 > 0
						topPriority += 1;
					}
				} else if (factorDistanceX <= factorDistanceY) {
					if (leftPriority === 2) {
						// 2 > 1
						leftPriority -= 1;
						// -1 > 0
						rightPriority += 1;
					} else {
						// 2 > 1
						rightPriority -= 1;
						// -1 > 0
						leftPriority += 1;
					}
				}

				const overlap = [];
				for (let i = 3; i >= -3; --i) {
					if (topPriority === i) {
						overlap.push({ name: 'top', priority: topPriority });
					}
					if (rightPriority === i) {
						overlap.push({ name: 'right', priority: rightPriority });
					}
					if (leftPriority === i) {
						overlap.push({ name: 'left', priority: leftPriority });
					}
					if (bottomPriority === i) {
						overlap.push({ name: 'bottom', priority: bottomPriority });
					}
				}

				return { collidingProduct: otherProduct, overlap };
			}

			return { collidingProduct, overlap };
		},
		{ collidingProduct: null, overlap: null }
	);

const findOtherProductY = (otherProductsOnShelf, targetShelfTop, targetShelfBottom, item, x, y) =>
	otherProductsOnShelf.reduce(
		(otherProductY, otherProduct) => {
			if (
				// match within x bounds
				x + item.getSize(item) > otherProduct.x &&
				x < otherProduct.x + otherProduct.getSize(otherProduct) &&
				// only include products below the current item visually (with a higher y than the given y)
				otherProduct.y > y &&
				// find the highest visual (lowest number) y
				otherProduct.y < otherProductY &&
				// that is still above the targetShelfTop (within the shelf) if the item is placed
				// on top of it
				otherProduct.y - item.height > targetShelfTop
			) {
				return otherProduct.y;
			}

			return otherProductY;
		},
		// start from the bottom of the shelf
		targetShelfBottom
	);

const findNearestFreePosition = (
	collidingProduct,
	overlap,
	otherProductsOnShelf,
	targetShelfTop,
	targetShelfBottom,
	leftBound,
	rightBound,
	item,
	x,
	y
) => {
	return overlap.reduce(
		({ freeX, freeY }, overlapOption) => {
			if (freeX && freeY) {
				return { freeX, freeY };
			}

			let tryX = null;
			let tryY = null;

			if (overlapOption.name === 'top') {
				// console.log('trying to find a free position on top');
				tryX = x;
				tryY = Math.max(targetShelfTop, collidingProduct.y - item.height);
			} else if (overlapOption.name === 'bottom') {
				// console.log('trying to find a free position on bottom');
				tryX = x;
				tryY = Math.min(
					targetShelfBottom - item.height,
					collidingProduct.y + collidingProduct.height
				);
			} else if (overlapOption.name === 'right') {
				// console.log('trying to find a free position on right');
				tryX = Math.min(
					rightBound,
					collidingProduct.x + collidingProduct.getSize(collidingProduct)
				);
				tryY =
					findOtherProductY(
						otherProductsOnShelf,
						targetShelfTop,
						targetShelfBottom,
						item,
						tryX
					) - item.height;
			} else if (overlapOption.name === 'left') {
				// console.log('trying to find a free position on left');
				tryX = Math.max(leftBound, collidingProduct.x - item.getSize(item));
				tryY =
					findOtherProductY(
						otherProductsOnShelf,
						targetShelfTop,
						targetShelfBottom,
						item,
						tryX
					) - item.height;
			}

			const collidingProductAfterOverlapOption = findCollidingProductWithItem(
				otherProductsOnShelf,
				item,
				tryX,
				tryY
			);
			if (!collidingProductAfterOverlapOption) {
				return { freeX: tryX, freeY: tryY };
			}

			return { freeX, freeY };
		},
		{ freeX: null, freeY: null }
	);
};

const ShelvingUnit = forwardRef(
	(
		{
			getTextContent,
			deleteProduct,
			depth,
			rotateProduct,
			height,
			onBeginDrag,
			onDropProduct,
			onIsDragging,
			openInfoModal,
			placedProducts,
			shelveThickness,
			textureUrl,
			totalShelves,
			width,
			isOnViewClosetPage
		},
		ref
	) => {
		const domNode = useRef();

		const shelveThicknessRef = useRef();
		shelveThicknessRef.current = shelveThickness;

		const totalShelvesRef = useRef();
		totalShelvesRef.current = totalShelves;

		const shelvesRef = useRef();
		shelvesRef.current = Array.from(new Array(totalShelves)).map((_, index) => ({
			id: `shelve-${index + 1}`
		}));

		// console.log('totalShelves', totalShelves);

		const placedProductsRef = useRef();
		placedProductsRef.current = placedProducts;

		const dropProduct = useCallback(
			(
				boundingClientRect,
				placedProducts,
				item,
				dropX,
				dropY,
				isMovingPlacedProduct,
				oldX,
				oldY
			) => {
				let leftBound = OUTER_PLANKS_THICKNESS;
				let rightBound =
					boundingClientRect.width - OUTER_PLANKS_THICKNESS - item.getSize(item);
				let topBound = OUTER_PLANKS_THICKNESS;
				let bottomBound = boundingClientRect.height - OUTER_PLANKS_THICKNESS - item.height;

				// console.log('dropping product', item);
				// console.log('totalShelvesRef.current handleDrop', totalShelvesRef.current);
				// console.log('dropping at x,y', dropX, dropY);

				let x = Math.max(leftBound, Math.min(dropX, rightBound));
				let y = Math.max(topBound, Math.min(dropY, bottomBound));

				const shelveHeight =
					(boundingClientRect.height - OUTER_PLANKS_THICKNESS) / totalShelvesRef.current;
				// console.log('shelveHeight', shelveHeight);
				const shelveTops = shelvesRef.current.map((_shelve, index) => {
					if (index === 0) {
						return OUTER_PLANKS_THICKNESS + shelveHeight - shelveThicknessRef.current;
					}

					if (index === shelvesRef.current.length - 1) {
						return boundingClientRect.height - OUTER_PLANKS_THICKNESS;
					}

					let shelveTop =
						OUTER_PLANKS_THICKNESS + shelveHeight - shelveThicknessRef.current;
					return shelveTop + index * shelveHeight;
				});
				// console.log('shelveTops', shelveTops);

				let nearestShelveTopIndex = shelveTops.findIndex(
					shelveTop => shelveTop >= y + item.height
				);
				if (nearestShelveTopIndex === -1) {
					nearestShelveTopIndex = shelveTops.length - 1;
				}
				// console.log('nearestShelveTopIndex', nearestShelveTopIndex);

				const targetShelfTop =
					(shelveTops[nearestShelveTopIndex - 1] || 0) +
					(nearestShelveTopIndex - 1 <= 0
						? OUTER_PLANKS_THICKNESS
						: shelveThicknessRef.current);
				const targetShelfBottom = shelveTops[nearestShelveTopIndex];

				// console.log('DROP targetShelfTop', targetShelfTop);
				// console.log('DROP targetShelfBottom', targetShelfBottom);

				if (!targetShelfBottom) {
					// console.log('item is too far below the closet');
					if (isMovingPlacedProduct) {
						if (oldX && oldY) {
							return { ...item, x: oldX, y: oldY };
						}
						return item;
					}
					return;
				}

				if (targetShelfBottom - targetShelfTop < item.height) {
					// console.log('item is too big (in height) for this shelf');
					if (isMovingPlacedProduct) {
						if (oldX && oldY) {
							return { ...item, x: oldX, y: oldY };
						}
						return item;
					}
					return;
				}

				y = Math.max(targetShelfTop, Math.min(y, targetShelfBottom));

				// console.log('constrained within target shelf x,y', x, y);

				const otherProductsOnShelf = placedProducts.filter(
					product =>
						product.y + product.height <= targetShelfBottom &&
						product.y + product.height > targetShelfBottom - shelveHeight &&
						product.boxId !== item.boxId
				);

				// console.log('otherProductsOnShelf', otherProductsOnShelf);
				const { collidingProduct, overlap } = findCollidingProductAndOverlapWithItem(
					otherProductsOnShelf,
					item,
					x,
					y
				);

				// console.log('collidingProduct', collidingProduct);
				// console.log('overlap', overlap);

				if (collidingProduct) {
					const { freeX, freeY } = findNearestFreePosition(
						collidingProduct,
						overlap,
						otherProductsOnShelf,
						targetShelfTop,
						targetShelfBottom,
						leftBound,
						rightBound,
						item,
						x,
						y
					);

					if (freeX !== null && freeY !== null) {
						// change the x and y to 'snap' the dropped item into the nearest free position
						x = freeX;
						y = freeY;

						// console.log('found freeX', freeX, 'freeY', freeY);
					} else {
						// console.log('no free available position found');
						if (isMovingPlacedProduct) {
							if (oldX && oldY) {
								return { ...item, x: oldX, y: oldY };
							}
							return item;
						}
						return;
					}
				} else {
					// stack on top of the highest other product within the item's x bounds,
					// or on top of the targetShelfTop if no other products are within the x bounds.
					const otherProductY = findOtherProductY(
						otherProductsOnShelf,
						targetShelfTop,
						targetShelfBottom,
						item,
						x,
						y
					);
					// console.log(
					// 	'found otherProductY (or floor y)',
					// 	otherProductY,
					// 	'item.height',
					// 	item.height
					// );
					// only change the y, keep the x exactly where the item is dropped
					y = otherProductY - item.height;
				}

				const product = { ...item, x, y, dropX, dropY };

				// console.log('placing at x,y', x, y);

				// if (oldX && oldY) {
				// 	// TODO check placedProducts within product's x bounds and see if one is
				// 	// floating, if so, call findOtherProductY for it and let App know TODO onAdjustProduct
				// 	placedProducts.forEach(otherProduct => {
				// 		if (
				// 			// exclude current product
				// 			otherProduct.guid !== product.guid &&
				// 			// match within x bounds
				// 			oldX + product.getSize(product) > otherProduct.x &&
				// 			oldX < otherProduct.x + otherProduct.getSize(otherProduct)
				// 		) {
				// 			// console.log('potentially floating product?', otherProduct);
				// 		}
				// 	})
				// }

				return product;
			},
			[]
		);

		const handleDrop = useCallback(
			(item, monitor) => {
				const boundingClientRect = domNode.current.getBoundingClientRect();
				const dragSourceClientOffset = monitor.getSourceClientOffset();

				let dropX = dragSourceClientOffset.x - boundingClientRect.left;
				let dropY = dragSourceClientOffset.y - boundingClientRect.top;

				// console.log('HANDLE DROP item', item, 'dropX, dropY', dropX, dropY);

				if (item.type === 'PLACED_PRODUCT' && placedProducts.length > 1) {
					const index = placedProducts.findIndex(
						placedProduct => placedProduct.boxId === item.boxId
					);
					// console.log('PLACED_PRODUCT index', index);
					const { x: oldX, y: oldY } = placedProducts[index];
					let updatedPlacedProducts = [
						...placedProducts.slice(0, index),
						{ ...placedProducts[index], x: dropX, y: dropY },
						...placedProducts.slice(index + 1)
					];
					// console.log(
					// 	'PLACED_PRODUCT updatedPlacedProducts[index]',
					// 	updatedPlacedProducts[index]
					// );

					const sortedPlacedProducts = updatedPlacedProducts.sort(
						(productA, productB) => {
							if (productA.y > productB.y) {
								return -1;
							}
							if (productA.y < productB.y) {
								return 1;
							}
							return 0;
						}
					);
					// console.log('FIX FLOATING BOXES sortedPlacedProducts', sortedPlacedProducts);
					let i = 0;
					let l = sortedPlacedProducts.length;
					while (i < l) {
						let updatedDroppedProduct = dropProduct(
							boundingClientRect,
							sortedPlacedProducts,
							sortedPlacedProducts[i],
							sortedPlacedProducts[i].x,
							sortedPlacedProducts[i].y,
							true,
							oldX,
							oldY
						);
						sortedPlacedProducts[i] = updatedDroppedProduct;
						// console.log('i', i, 'sortedPlacedProducts[i]', sortedPlacedProducts[i]);
						i = i + 1;
					}

					// console.log('FIX FINAL sortedPlacedProducts', sortedPlacedProducts);

					onDropProduct(sortedPlacedProducts);
					return sortedPlacedProducts.find(p => p.boxId === item.boxId);
				}

				const droppedProduct = dropProduct(
					boundingClientRect,
					placedProducts,
					item,
					dropX,
					dropY
				);

				onDropProduct(item.type === 'PLACED_PRODUCT' ? [droppedProduct] : droppedProduct);
				return droppedProduct;
			},
			[dropProduct, onDropProduct, placedProducts]
		);

		const determineIfRotateProductIsPossible = useCallback(
			product => {
				// This is the depth to check after product.rotated would be changed, so that's why
				// if rotated > use depth, else use width
				const rotatedProductDepth = product.rotated ? product.depth : product.width;
				// console.log('product', product);
				// console.log('rotatedProductDepth', rotatedProductDepth);
				// console.log('depth', depth);
				if (depth < rotatedProductDepth) {
					return { possible: false, reason: 'closet-depth' };
				}

				const boundingClientRect = domNode.current.getBoundingClientRect();

				const shelveHeight =
					(boundingClientRect.height - OUTER_PLANKS_THICKNESS) / totalShelvesRef.current;
				// console.log('shelveHeight', shelveHeight);
				const shelveTops = shelvesRef.current.map((_shelve, index) => {
					if (index === 0) {
						return OUTER_PLANKS_THICKNESS + shelveHeight - shelveThicknessRef.current;
					}

					if (index === shelvesRef.current.length - 1) {
						return boundingClientRect.height - OUTER_PLANKS_THICKNESS;
					}

					let shelveTop =
						OUTER_PLANKS_THICKNESS + shelveHeight - shelveThicknessRef.current;
					return shelveTop + index * shelveHeight;
				});
				// console.log('shelveTops', shelveTops);

				const nearestShelveTopIndex = shelveTops.findIndex(
					shelveTop => shelveTop > product.y + product.height
				);
				const targetShelfBottom = shelveTops[nearestShelveTopIndex];

				// console.log('targetShelfBottom', targetShelfBottom);

				const otherProductsOnShelf = placedProductsRef.current.filter(
					otherProduct =>
						otherProduct.y + otherProduct.height <= targetShelfBottom &&
						otherProduct.y + otherProduct.height > targetShelfBottom - shelveHeight &&
						otherProduct.boxId !== product.boxId
				);

				// console.log('otherProductsOnShelf', otherProductsOnShelf);
				// This is the right bound after product.rotated would be changed, so that's why
				// if rotated > use width, else use depth
				const rotatedProductRightBound = product.rotated
					? product.x + product.width
					: product.x + product.depth;
				// console.log('rotatedProductRightBound', rotatedProductRightBound);
				if (rotatedProductRightBound > boundingClientRect.width) {
					// console.log('CLOSET BOUNDARY ON THE RIGHT PREVENTS ROTATION');
					return { possible: false, reason: 'closet-right-bound' };
				}

				return otherProductsOnShelf.some(otherProduct => {
					if (
						otherProduct.y + otherProduct.height === product.y + product.height &&
						otherProduct.x > product.x &&
						rotatedProductRightBound > otherProduct.x
					) {
						// console.log('OTHER PRODUCT PREVENTS ROTATION', otherProduct);
						return true;
					}
					return false;
				})
					? { possible: false, reason: 'other-product' }
					: { possible: true };
			},
			[depth]
		);

		// eslint-disable-next-line no-unused-vars
		const [_, connectDropTarget] = useDrop({
			accept: ['PLACED_PRODUCT', 'PRODUCT'],
			drop: handleDrop
		});

		const handleRef = useCallback(
			domNodeOrNull => {
				connectDropTarget(domNodeOrNull);
				domNode.current = domNodeOrNull;

				if (ref) {
					ref.current = domNodeOrNull;
				}
			},
			[connectDropTarget, ref]
		);

		return (
			<Flex
				alignSelf="flex-end"
				flexDirection="column"
				flex="none"
				minWidth={width}
				width={width}
				minHeight={height}
				height={height}
				ref={handleRef}
				marginRight="25px"
				marginBottom="25px"
			>
				<VerticalPlank thickness={`${OUTER_PLANKS_THICKNESS}px`} textureUrl={textureUrl} />
				<VerticalPlank
					isOnTheRight
					thickness={`${OUTER_PLANKS_THICKNESS}px`}
					textureUrl={textureUrl}
				/>

				<HorizontalPlank
					isOnTop
					thickness={`${OUTER_PLANKS_THICKNESS}px`}
					textureUrl={textureUrl}
				/>

				{shelvesRef.current.map((shelve, index) => (
					<Shelve
						key={shelve.id}
						shelve={shelve}
						thickness={
							index === shelvesRef.current.length - 1
								? OUTER_PLANKS_THICKNESS
								: shelveThickness
						}
						textureUrl={textureUrl}
					/>
				))}

				{placedProducts.map(placedProduct => {
					return (
						<PlacedProduct
							key={placedProduct.boxId}
							getTextContent={getTextContent}
							closetDepth={depth}
							closetWidth={
								(width - (2 * OUTER_PLANKS_THICKNESS) / PIXELS_PER_CM) *
								PIXELS_PER_CM
							}
							deleteProduct={deleteProduct}
							rotateProduct={rotateProduct}
							rotateProductIsPossible={determineIfRotateProductIsPossible}
							onBeginDrag={onBeginDrag}
							onIsDragging={onIsDragging}
							openInfoModal={openInfoModal}
							product={placedProduct}
							isOnViewClosetPage={isOnViewClosetPage}
						/>
					);
				})}
			</Flex>
		);
	}
);

export default ShelvingUnit;
