/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled/macro';

import { useCallback, useState } from 'react';

import { useLocation } from 'react-router';

import Flex from '../../box-model/Flex';

import Button from '../../button/Button';
import BackTextLink from '../../button/BackTextLink';

import SingleLineInput from '../../input/SingleLineInput';

import Heading2 from '../../typography/Heading2';

import ContentContainer from '../ContentContainer';

import sendIconUrl from './send-icon.svg';

const Paragraph = styled.p`
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 300;
	line-height: 1.25;
	color: var(--warm-grey);
	text-align: center;
	margin-top: 0;
	margin-bottom: 25px;
	white-space: pre-wrap;
`;

const Form = Flex.withComponent('form');

export default function SetPasswordPage({ getTextContent, onSubmit }) {
	const location = useLocation();
	const next = location && location.state && location.state.next ? location.state.next : '/';

	const queryParams = new URLSearchParams(location.search);

	const [password, setPassword] = useState('');

	const onSubmitButtonClick = useCallback(
		event => {
			event.preventDefault();
			onSubmit({ password, t: queryParams.get('t') }, next);
		},
		[onSubmit, password, queryParams, next]
	);

	const wachtwoordInstellenTitel = getTextContent('wachtwoordInstellenTitel');
	const wachtwoordInstellenTekst = getTextContent('wachtwoordInstellenTekst');
	const nieuwWachtwoordInstellenWachtwoord = getTextContent('nieuwWachtwoordInstellenWachtwoord');
	const terug = getTextContent('terug');
	const instellen = getTextContent('instellen');

	return (
		<ContentContainer>
			<Flex width={530} flexDirection="column" alignItems="center">
				<Heading2>{wachtwoordInstellenTitel}</Heading2>

				<Flex flexDirection="column" alignItems="center" width={410}>
					<Paragraph>{wachtwoordInstellenTekst}</Paragraph>

					<Form
						flexDirection="column"
						spaceVertical="24px"
						alignItems="stretch"
						width={350}
					>
						<SingleLineInput
							placeholder={nieuwWachtwoordInstellenWachtwoord}
							type="password"
							onChange={setPassword}
							isValid={value => value && value.length >= 6}
							value={password}
						/>

						<Flex
							alignSelf="stretch"
							alignItems="stretch"
							flex="1"
							flexDirection="row"
							justifyContent="space-between"
						>
							<BackTextLink label={terug} />

							<Button
								label={instellen}
								iconAlt="send icon"
								iconUrl={sendIconUrl}
								isDisabled={!password || password.length < 6}
								onClick={onSubmitButtonClick}
								type="primary"
							/>
						</Flex>
					</Form>
				</Flex>
			</Flex>
		</ContentContainer>
	);
}
