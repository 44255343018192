/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import Flex from '../box-model/Flex';

const contentContainerStyles = css`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
`;

export default function ContentContainer({
	children,
	alignItems = 'center',
	justifyContent = 'center',
	flexDirection = 'column',
	spaceHorizontal = null
}) {
	return (
		<Flex
			css={contentContainerStyles}
			flexDirection={flexDirection}
			flex="1"
			alignItems={alignItems}
			justifyContent={justifyContent}
			spaceHorizontal={spaceHorizontal}
		>
			{children}
		</Flex>
	);
}
