import React from 'react';
import ReactDOM from 'react-dom';

import { DndProvider } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';

import { BrowserRouter as Router } from 'react-router-dom';

import GlobalStyles from './global-styles/GlobalStyles';

import AppContainer from './AppContainer';

if (!HTMLCanvasElement.prototype.toBlob) {
	Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
		value: function(callback, type, quality) {
			var canvas = this;
			setTimeout(function() {
				var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
					len = binStr.length,
					arr = new Uint8Array(len);

				for (var i = 0; i < len; i++) {
					arr[i] = binStr.charCodeAt(i);
				}

				callback(new Blob([arr], { type: type || 'image/png' }));
			});
		}
	});
}

ReactDOM.render(
	<Router>
		<GlobalStyles />

		<DndProvider backend={MultiBackend} options={HTML5toTouch}>
			<AppContainer />
		</DndProvider>
	</Router>,
	document.getElementById('root')
);
