/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import { memo, useState } from 'react';

import { animated, useSpring } from 'react-spring';

import display from '../box-model/display';

import Product from './Product';

const styles = css`
	${display('inline-block')}

	position: absolute;
`;

export default memo(function PlacedProduct({
	getTextContent,
	closetDepth,
	deleteProduct,
	rotateProduct,
	rotateProductIsPossible,
	onBeginDrag,
	openInfoModal,
	product,
	isOnViewClosetPage
}) {
	const [isDragging, setIsDragging] = useState(false);
	const cursor = isDragging ? 'grabbing' : 'grab';
	const display = isDragging ? 'none' : 'inline-block';

	const [props, set, stop] = useSpring(() => ({
		x: product.dropX || product.x,
		y: product.dropY || product.y,
		config: {
			mass: product.mass,
			tension: 420,
			friction: 30,
			clamp: true
		},
		onRest: () => {
			delete product.dropX;
			delete product.dropY;
		}
	}));

	if (!isDragging) {
		set({
			reset: true,
			from: { x: product.dropX || product.x, y: product.dropY || product.y },
			to: { x: product.x, y: product.y },
			onRest: () => {
				delete product.dropX;
				delete product.dropY;
			}
		});
	} else {
		stop();
	}

	return (
		<animated.div css={styles} style={{ cursor, display, left: props.x, top: props.y }}>
			<Product
				getTextContent={getTextContent}
				isPlaced
				closetDepth={closetDepth}
				onBeginDrag={onBeginDrag}
				onIsDragging={setIsDragging}
				deleteProduct={deleteProduct}
				rotateProduct={rotateProduct}
				rotateProductIsPossible={rotateProductIsPossible}
				openInfoModal={openInfoModal}
				product={product}
				isOnViewClosetPage={isOnViewClosetPage}
			/>
		</animated.div>
	);
});
