/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';

const styles = css`
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	/* z-index: 14; */
	border-color: transparent;
	border-right-color: transparent;
	border-style: solid;
	border-width: 1px 1px 0 1px;
	color: #333;
	display: inline-block;
	font-family: 'Nunito', sans-serif, sans-serif;
	font-size: 15px;
	height: 48px;
	line-height: 20px;
	position: relative;
	vertical-align: top;
	white-space: normal;

	:hover {
		border-color: #e6e6e6;
		background: #fff;
	}
`;

const openerStyles = css`
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	background: transparent;
	color: #333;
	font-family: 'Nunito', sans-serif, sans-serif;
	font-size: 15px;
	height: 100%;
	line-height: 20px;
	padding-bottom: 1px;
	position: relative;
	/* visibility: visible; */
	white-space: normal;
	width: 63px;

	:after {
		background: url('https://www.sunware.com/content/files/icons/arrow-down-grey.png');
		bottom: 0;
		content: '';
		height: 5px;
		margin: auto;
		position: absolute;
		right: 13px;
		top: 0;
		width: 11px;
	}
`;

const selectedFlagStyles = css`
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	border-style: none;
	bottom: 0;
	color: #333;
	font-family: 'Nunito', sans-serif, sans-serif;
	font-size: 15px;
	left: 17px;
	line-height: 20px;
	margin: auto;
	max-width: 100%;
	position: absolute;
	top: 0;
	/* visibility: visible; */
	white-space: normal;
`;

const summaryStyles = css`
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	background: #fff;
	border-color: #e6e6e6;
	border-style: solid;
	border-width: 1px;
	color: #333;
	display: block;
	font-family: 'Nunito', sans-serif, sans-serif;
	font-size: 15px;
	left: auto;
	line-height: 20px;
	max-height: 80vw;
	min-width: 127px;
	overflow-y: auto;
	padding-top: 4px;
	position: absolute;
	right: -1px;
	top: 100%;
	visibility: visible;
	white-space: normal;
	z-index: 10;
`;

const menuItemStyles = css`
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	background-color: transparent;
	color: #333;
	cursor: pointer;
	display: block;
	font-family: 'Nunito', sans-serif, sans-serif;
	font-size: 15px;
	line-height: 40px;
	padding: 0 30px 0 18px;
	text-decoration: none;
	/* visibility: hidden; */
	white-space: nowrap;
	word-wrap: break-word;

	:hover {
		text-decoration: none;
		background: #f7f7f7;
	}
`;

const flagStyles = css`
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	border-style: none;
	color: #333;
	cursor: pointer;
	font-family: 'Nunito', sans-serif, sans-serif;
	font-size: 15px;
	line-height: 40px;
	margin-right: 13px;
	max-width: 100%;
	white-space: nowrap;
	word-wrap: break-word;
	//
	min-width: 16px;
	min-height: 11px;
`;

export default function LanguageSelector({ currentLocale, changeLocale, locales }) {
	const [isHovered, setIsHovered] = React.useState(false);

	const activeLocale = locales.find(locale => locale.isoCode === currentLocale);

	return (
		<div
			css={styles}
			onMouseOver={() => setIsHovered(true)}
			onMouseOut={() => setIsHovered(false)}
		>
			{activeLocale && (
				<div css={openerStyles}>
					<img
						src={activeLocale.flagUrl}
						aria-label={activeLocale.isoCode}
						alt=""
						css={selectedFlagStyles}
					/>
				</div>
			)}
			<div css={summaryStyles} style={{ visibility: isHovered ? 'visible' : 'hidden' }}>
				{locales.map((locale, index) => (
					<span
						key={index}
						onClick={() => changeLocale(locale.isoCode)}
						css={menuItemStyles}
					>
						<img
							src={locale.flagUrl}
							aria-label={locale.isoCode}
							alt=""
							css={flagStyles}
						/>
						{locale.label}
					</span>
				))}
			</div>
		</div>
	);
}
