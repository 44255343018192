import { useCallback, useState } from 'react';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled/macro';

import { useHistory, useRouteMatch } from 'react-router-dom';

import Flex from '../../box-model/Flex';

import ShelvingUnit from '../configurator/shelving-unit/ShelvingUnit';
import InfoModal from '../configurator//InfoModal';

import ContentContainer from '../ContentContainer';

import arrowForwardIconUrl from './arrow-forward-icon.svg';
import arrowRightIconUrl from './arrow-right-icon.svg';

import Button from '../../button/Button';

const PIXELS_PER_CM = 2.25;

const ViewPane = styled.div`
	width: 380px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 5px;
	padding: 10px;
	margin-left: 15px;
`;
const Heading = styled.h1`
	font-family: 'Pacifico';
	font-size: 36px;
	line-height: 0.83;
	text-align: left;
	color: var(--purple-brown);
	margin: 0 0 15px 0;
`;

const SubHeading = styled.h2`
	font-family: Roboto;
	font-size: 20px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: var(--pastel-red);
	margin: 0 0 10px 0;
`;

const Paragraph = styled.p`
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 300;
	line-height: 1.25;
	text-align: left;
	color: var(--warm-grey);
	margin: 0 0 10px 0;
	white-space: pre-wrap;
`;

const TextLink = styled.a`
	display: flex;
	flex-direction: row;
	flex: none;
	align-items: center;
	font-family: 'Roboto';
	font-size: 14px;
	color: var(--warm-grey);
	cursor: pointer;
	text-decoration: none;
	margin-top: 10px;

	transition: color 0.2s ease-in-out, text-decoration 0.2s ease-in-out;
	:hover {
		color: var(--dark-mauve);
		text-decoration: underline;
	}

	img {
		flex: none;
	}
	span {
		flex: none;
	}
`;

export default function ViewClosetPage({ currentCloset, getTextContent }) {
	const { url } = useRouteMatch();
	const history = useHistory();

	const matchesInfoModal = useRouteMatch(`${url}/product`);

	const [infoModalProduct, setInfoModalProduct] = useState(null);
	const openInfoModal = useCallback(
		product => {
			history.push(`${url}/product`);

			setInfoModalProduct(product);
		},
		[history, url]
	);
	const closeInfoModal = useCallback(() => history.goBack(), [history]);

	// "De kast van"
	const kastBekijkenTitelVoorvoegsel = getTextContent('kastBekijkenTitelVoorvoegsel');
	// "heeft deze kast gemaakt en ingericht met de Sunware BoxBuilder."
	const kastBekijkenTekstAchtervoegsel = getTextContent('kastBekijkenTekstAchtervoegsel');
	// "Klik op de producten in de kast om meer informatie over het product te lezen."
	const kastBekijkenInstructie = getTextContent('kastBekijkenInstructie');
	// "Ook een kast maken?"
	const kastBekijkenSubtitel = getTextContent('kastBekijkenSubtitel');
	// "Wil je zelf ook een kast maken en inrichten met allerlei verschillende boxen? Ga dan zelf aan de slag met de Sunware BoxBuilder!"
	const kastBekijkenZelfMakenTekst = getTextContent('kastBekijkenZelfMakenTekst');
	// "Naar de BoxBuilder"
	const kastBekijkenZelfMakenButton = getTextContent('kastBekijkenZelfMakenButton');
	// "Of bekijk het complete Sunware assortiment"
	const kastBekijkenSunwareWebsiteLink = getTextContent('kastBekijkenSunwareWebsiteLink');

	return (
		<ContentContainer alignItems="stretch" justifyContent="flex-start" flexDirection="row">
			<Flex
				alignItems="center"
				justifyContent="flex-start"
				flexDirection="row"
				flex="1"
				spaceHorizontal="10px"
			>
				<ViewPane>
					<Heading>
						{kastBekijkenTitelVoorvoegsel} {currentCloset.ownerFirstName}
					</Heading>

					<Paragraph>
						{currentCloset.ownerFirstName} {kastBekijkenTekstAchtervoegsel}
					</Paragraph>

					<Paragraph>{kastBekijkenInstructie}</Paragraph>

					<SubHeading>{kastBekijkenSubtitel}</SubHeading>

					<Paragraph>{kastBekijkenZelfMakenTekst}</Paragraph>

					<Button
						label={kastBekijkenZelfMakenButton}
						iconUrl={arrowForwardIconUrl}
						iconAlt="pijl vooruit icoon"
						width="24px"
						height="24px"
						onClick={() => history.push('/')}
					/>

					<TextLink href="https://www.sunware.com/">
						<span>{kastBekijkenSunwareWebsiteLink}</span>

						<img
							src={arrowRightIconUrl}
							width={24}
							height={24}
							alt="arrow right icon"
						/>
					</TextLink>
				</ViewPane>

				<Flex flex="1" alignItems="flex-end" alignSelf="stretch" justifyContent="flex-end">
					<ShelvingUnit
						getTextContent={getTextContent}
						deleteProduct={null}
						rotateProduct={null}
						onBeginDrag={() => {}}
						onDropProduct={() => {}}
						openInfoModal={openInfoModal}
						placedProducts={currentCloset ? currentCloset.boxes : []}
						shelveThickness={currentCloset.shelveThickness * PIXELS_PER_CM}
						totalShelves={currentCloset.totalShelves}
						width={currentCloset.width * PIXELS_PER_CM}
						height={currentCloset.height * PIXELS_PER_CM}
						depth={currentCloset.depth * PIXELS_PER_CM}
						isOnViewClosetPage
					/>
				</Flex>
			</Flex>

			{matchesInfoModal && infoModalProduct && (
				<InfoModal
					getTextContent={getTextContent}
					onCloseModalButtonClick={closeInfoModal}
					product={infoModalProduct}
				/>
			)}
		</ContentContainer>
	);
}
