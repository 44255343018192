/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled/macro';

import { useLocation } from 'react-router';

import Flex from '../../box-model/Flex';

import Button from '../../button/Button';

import Heading2 from '../../typography/Heading2';

import ContentContainer from '../ContentContainer';

import BigIconButton from './BigIconButton';

import arrowForwardIconUrl from './arrow-forward-icon.svg';
import { useState, useEffect } from 'react';

const Paragraph = styled.p`
	font-family: 'Roboto';
	font-size: 20px;
	font-weight: 300;
	line-height: 1.5;
	color: var(--warm-grey);
	text-align: center;
	margin-top: 0;
	margin-bottom: 0;
	white-space: pre-wrap;
`;

const Question = styled.p`
	font-family: Roboto;
	font-size: 20px;
	font-weight: 500;
	line-height: 1.5;
	color: var(--pastel-red);
	white-space: pre-wrap;
`;

export default function WizardPage({ auth, currentLocale, getTextContent, setCurrentCloset }) {
	const location = useLocation();
	const next =
		location && location.state && location.state.next
			? location.state.next
			: '/aanpassen/de-kamer';

	const [presets, setPresets] = useState([]);
	const [selectedPreset, setSelectedPreset] = useState(presets[0]);

	useEffect(() => {
		auth.fetch(`/preset?languageCode=${currentLocale}`)
			.then(response => response.json())
			.then(presets => {
				// console.log('GET PRESET RESPONSE JSON', presets);

				if (presets && Array.isArray(presets)) {
					setPresets(presets);
				} else {
					// console.log('GET PRESET UNEXPECTED RESPONSE JSON', presets);
				}
			})
			.catch(error => {
				// console.log('GET PRESET ERROR', error);
			});
	}, [auth, currentLocale]);

	const wizardTitel = getTextContent('wizardTitel');
	const wizardTekst = getTextContent('wizardTekst');
	const wizardInWelkeKamer = getTextContent('wizardInWelkeKamer');
	const volgende = getTextContent('volgende');

	return (
		<ContentContainer>
			<Flex width={560} flexDirection="column" alignItems="center">
				<Heading2>{wizardTitel}</Heading2>

				<Flex flexDirection="column" alignItems="center" width={410}>
					<Paragraph>{wizardTekst}</Paragraph>

					<Question>{wizardInWelkeKamer}</Question>

					<Flex flexDirection="row" justifyContent="space-between" marginBottom="24px">
						{presets.map(preset => (
							<BigIconButton
								key={preset.guid}
								isSelected={selectedPreset === preset}
								label={preset.title}
								onClick={() => {
									setSelectedPreset(preset);
									setCurrentCloset(currentCloset => ({
										...currentCloset,
										isDirty: true,
										backgroundGuid: preset.background.guid,
										closetTextureGuid: preset.closetTexture.guid
									}));
								}}
								iconUrl={preset.icon}
								iconSelectedUrl={preset.iconActive}
								iconAlt={preset.title}
							/>
						))}
					</Flex>

					<Button
						label={volgende}
						iconAlt="arrow forward icon"
						iconUrl={arrowForwardIconUrl}
						to={next}
						type="primary"
					/>
				</Flex>
			</Flex>
		</ContentContainer>
	);
}
