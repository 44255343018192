/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import Flex from '../box-model/Flex';

import lightBackgroundUrl from './mobile-background-3x.jpg';

export default function MobileBackgroundContainer() {
	return (
		<Flex flex="1" overflow="hidden">
			<img
				css={css(`display: block; height: 100%; width: 100%; object-fit: cover;`)}
				src={lightBackgroundUrl}
				alt="pagina achtergrond"
			/>
		</Flex>
	);
}
