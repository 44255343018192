import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

import display from './display';

const Block = styled('div', {
	shouldForwardProp: prop => isPropValid(prop) && prop !== 'width' && prop !== 'height'
})(
	({
		children: _children,
		theme: _theme,
		paddingHorizontal,
		paddingVertical,
		spaceHorizontal,
		spaceVertical,
		...otherCssProps
	}) => ({
		...display('block'),

		paddingTop: paddingVertical,
		paddingRight: paddingHorizontal,
		paddingBottom: paddingVertical,
		paddingLeft: paddingHorizontal,

		'> :not(:last-child)': {
			marginRight: spaceHorizontal,
			marginBottom: spaceVertical
		},

		...otherCssProps
	})
);

export default Block;
