/** @jsx jsx */
import { css, jsx } from '@emotion/core';

const styles = css`
	position: relative;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	height: 40px;
	border-radius: 5px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
	background-color: #ffffff;

	padding-left: 10px;
	padding-right: 10px;

	> :not(:last-child) {
		margin-right: 8px;
	}

	select {
		border: none;
		outline: none;

		flex: 1;

		font-family: 'Roboto';
		font-size: 16px;
		line-height: 1.25;

		color: var(--purple-brown);
		background-color: #ffffff;
	}
`;

export default function SingleSelect({ onChange, readOnly, options, value }) {
	return (
		<label css={styles}>
			<select onChange={onChange} disabled={readOnly} value={value}>
				{options.map(optionData => (
					<option key={optionData.guid} value={optionData.guid}>
						{optionData.name}
					</option>
				))}
			</select>
		</label>
	);
}
