/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled/macro';

const ColorContainer = styled.div`
	position: relative;
	box-sizing: border-box;
	flex: none;
	width: 40px;
	height: 40px;
	border-radius: 5px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
	cursor: pointer;
`;

const ColorClip = styled.div`
	position: relative;
	box-sizing: border-box;
	width: 40px;
	height: 40px;
	clip-path: inset(0 0 0 0 round 5px);
`;

const ColorTriangle = styled.div`
	position: absolute;
	box-sizing: border-box;
	width: 0;
	height: 0;
	border-width: 40px 40px 0 0;
	border-color: ${props => `${props.color} transparent transparent transparent`};
	border-style: solid;
	transform: ${props => `rotate(${props.rotate}deg)`};
`;

export default function Color({ colorOption, ...props }) {
	return (
		<ColorContainer {...props}>
			<ColorClip>
				<ColorTriangle color={'#' + colorOption.colour1.replace('#', '')} rotate={0} />
				<ColorTriangle color={'#' + colorOption.colour2.replace('#', '')} rotate={180} />
			</ColorClip>
		</ColorContainer>
	);
}
