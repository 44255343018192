import React from 'react';
import styled from '@emotion/styled';

import ReactSlider from 'react-slider';

const StyledSlider = styled(ReactSlider)`
	width: 100%;
	height: 32px;
`;

const StyledThumb = styled.div`
	cursor: ${props => (props.disabled ? 'default' : 'grab')};
	pointer-events: ${props => (props.disabled ? 'none' : 'all')};
	outline: none;

	width: 68px;
	height: 33px;
	border-radius: 15px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
	background-color: var(--dark-mauve);

	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 500;
	line-height: 1.25;
	color: #ffffff;

	display: flex;
	align-items: center;
	justify-content: center;

	transition: background-color 0.2s ease-in-out;
	:hover {
		background-color: var(--pastel-red);
	}

	&.active {
		background-color: var(--pastel-red);
	}

	div {
		flex: none;

		span {
			font-size: 12px;
		}
	}
`;

const Thumb = ({ props, state, sliderProps }) => (
	<StyledThumb {...props} disabled={sliderProps.disabled}>
		<div>
			{state.valueNow} {sliderProps.suffix && <span>{sliderProps.suffix}</span>}
		</div>
	</StyledThumb>
);

const StyledTrack = styled.div`
	.track {
		position: absolute;
		top: 15px;
		bottom: 15px;
		left: 0;
		right: 0;
		height: 3px;
		background-color: rgba(130, 90, 93, 0.25);
	}

	.left-bound {
		position: absolute;
		left: 0;
		margin-top: 9px;
		margin-bottom: 9px;
		width: 3px;
		height: 15px;
		background-color: var(--dark-mauve);
	}
	.min {
		position: absolute;
		left: 0;
		top: 22px;
		height: 17px;
		font-family: 'Roboto';
		font-size: 14px;
		font-weight: bold;
		line-height: 1.43;
		color: rgba(112, 112, 112, 0.25);
	}
	.right-bound {
		position: absolute;
		right: 0;
		margin-top: 9px;
		margin-bottom: 9px;
		width: 3px;
		height: 15px;
		background-color: var(--dark-mauve);
	}
	.max {
		position: absolute;
		right: 0;
		top: 22px;
		height: 17px;
		font-family: 'Roboto';
		font-size: 14px;
		font-weight: bold;
		line-height: 1.43;
		color: rgba(112, 112, 112, 0.25);
	}
`;

const Track = ({ props, state, sliderProps }) => (
	<StyledTrack {...props} index={state.index}>
		{state.index === 0 && <div className="left-bound"></div>}
		{state.index === 0 && <div className="min">{sliderProps.min}</div>}

		<div className="track"></div>

		{state.index === 1 && <div className="right-bound"></div>}
		{state.index === 1 && <div className="max">{sliderProps.max}</div>}
	</StyledTrack>
);

export default function Slider(sliderProps) {
	return (
		<StyledSlider
			renderTrack={(props, state) => (
				<Track props={props} state={state} sliderProps={sliderProps} key={state.index} />
			)}
			renderThumb={(props, state) => (
				<Thumb props={props} state={state} sliderProps={sliderProps} key={state.index} />
			)}
			{...sliderProps}
		/>
	);
}
