/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { useCallback, useRef, useState, useMemo } from 'react';
import ReactDOM from 'react-dom';

import Block from '../../box-model/Block';
import Flex from '../../box-model/Flex';

import Button from '../../button/Button';

import Heading2 from '../../typography/Heading2';
import Paragraph from '../../typography/Paragraph';

import ContentContainer from '../ContentContainer';

import CopyButton from './CopyButton';

import closeIconUrl from './close-icon.svg';
// import downloadPdfIconUrl from './download-pdf-icon.svg';
import editIconUrl from './edit-icon.svg';
import plusIconUrl from './plus-icon.svg';
import shareIconUrl from './share-icon.svg';
import warningIconUrl from './warning-icon.svg';

const Pane = styled.div`
	display: flex;
	flex-direction: column;
	width: 555px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 5px;
	padding-top: 14px;
	padding-bottom: 14px;
	padding-left: 12px;
	padding-right: 12px;
	margin-bottom: 25px;
	max-height: 350px;
	overflow-y: auto;
	overflow-x: hidden;
`;

const ClosetName = styled.h3`
	font-family: Roboto;
	font-size: 16px;
	font-weight: 500;
	color: var(--pastel-red);
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
const LastModifiedDate = styled.span`
	font-family: Roboto;
	font-size: 14px;
	font-weight: 300;
	color: var(--warm-grey);
`;

const ClosetUrlField = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	height: 40px;
	border-radius: 5px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
	background-color: #ffffff;
	align-items: center;

	padding-left: 10px;

	input {
		border: none;
		outline: none;

		flex: 1;

		font-family: 'Roboto';
		font-size: 16px;
		line-height: 1.25;

		color: var(--purple-brown);
	}
`;

const DeleteConfirmationPopup = styled.div`
	position: absolute;
	display: flex;
	flex-direction: row;
	top: ${props => `${props.position.y}px`};
	left: ${props => `${props.position.x}px`};
	width: ${props => props.width || '280px'};
	border-radius: 10px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
	border: 1px solid var(--pastel-red);
	background-color: #ffffff;
	padding: 10px;
	z-index: 3;

	> img {
		flex: none;
		margin-right: 5px;
	}
`;

const PopupHeading = styled.h3`
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: bold;
	line-height: 1.33;
	text-align: left;
	color: var(--pastel-red);
	white-space: pre-wrap;
	margin: 0;
`;
const PopupText = styled.p`
	font-family: 'Roboto';
	font-size: 14px;
	line-height: 1.33;
	text-align: left;
	color: var(--warm-grey);
	white-space: pre-wrap;
	margin: 0;
`;

const months = [
	'januari',
	'februari',
	'maart',
	'april',
	'mei',
	'juni',
	'juli',
	'augustus',
	'september',
	'oktober',
	'november',
	'december'
];

function DeleteConfirmation({ onSubmit, onCancel, portalRef, position }) {
	return ReactDOM.createPortal(
		<DeleteConfirmationPopup position={position}>
			<img src={warningIconUrl} alt="waarschuwing icoon" width="24px" height="24px" />
			<Block spaceVertical="10px">
				<PopupHeading>
					Weet je zeker dat je deze kast
					<br />
					wilt verwijderen?
				</PopupHeading>

				<PopupText>Dat kan niet ongedaan gemaakt worden.</PopupText>

				<Flex flexDirection="row" justifyContent="space-between">
					<Button label="Nee" onClick={onCancel} type="secondary" />
					<Button label="Ja" onClick={onSubmit} />
				</Flex>
			</Block>
		</DeleteConfirmationPopup>,
		portalRef.current
	);
}

function ClosetRow({
	closet,
	index,
	isLast,
	generateDownloadablePDF,
	getTextContent,
	showShareButtons,
	onDeleteButtonClick,
	onEditButtonClick,
	onShareButtonClick,
	popupPortalRef
}) {
	const inputRef = useRef(null);

	const copyUrlToClipboard = useCallback(() => {
		inputRef.current.select();
		window.document.execCommand('copy');
	}, []);

	const date = useMemo(() => {
		return new Date(closet.createDate);
	}, [closet.createDate]);

	const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] = useState(false);

	const delen = getTextContent('delen');
	const bewerken = getTextContent('bewerken');
	const kopieren = getTextContent('kopieren');

	return (
		<Block
			flex="none"
			paddingBottom={isLast ? 0 : 12.5}
			marginBottom={isLast ? 0 : 12.5}
			borderBottom={isLast ? null : '0.5px solid #eeeeee'}
		>
			{showDeleteConfirmationPopup && (
				<DeleteConfirmation
					onSubmit={() => onDeleteButtonClick(closet)}
					onCancel={() => setShowDeleteConfirmationPopup(false)}
					portalRef={popupPortalRef}
					position={{ x: 267, y: -35 + index * 66 }}
				/>
			)}

			<Flex flexDirection="row" spaceHorizontal="5px">
				<Block flex="1">
					<ClosetName>{closet.name}</ClosetName>

					<LastModifiedDate>
						Laatst gewijzigd: {date && date.getDate()} {date && months[date.getMonth()]}{' '}
						{date && date.getFullYear()}
					</LastModifiedDate>
				</Block>

				<Button
					label={delen}
					type="secondary"
					iconAlt="delen icoon"
					iconUrl={shareIconUrl}
					onClick={() => onShareButtonClick(closet.guid)}
				/>

				<Button
					label={bewerken}
					type="primary"
					iconAlt="bewerken icoon"
					iconUrl={editIconUrl}
					onClick={() => onEditButtonClick(closet)}
				/>

				<Button
					type="primary"
					iconAlt="verwijder icoon"
					iconUrl={closeIconUrl}
					onClick={() => setShowDeleteConfirmationPopup(true)}
				/>
			</Flex>

			{showShareButtons && (
				<Flex flexDirection="row" spaceHorizontal="5px" marginTop="12.5px">
					<ClosetUrlField>
						<input
							readOnly={true}
							type="text"
							ref={inputRef}
							value={`${window.location.href.replace(
								/\/mijn-kasten/g,
								''
							)}/bekijken?c=${closet.guid}`}
						/>

						<CopyButton label={kopieren} onClick={copyUrlToClipboard} />
					</ClosetUrlField>

					{/* <Button
						label="Download PDF"
						type="tertiary"
						iconAlt="download pdf icoon"
						iconUrl={downloadPdfIconUrl}
						onClick={() => generateDownloadablePDF(closet.guid)}
					/> */}
				</Flex>
			)}
		</Block>
	);
}

export default function MyClosetsPage({ closets, deleteCloset, editCloset, getTextContent }) {
	const [showShareButtonsForCloset, setShowShareButtonsForCloset] = useState(null);

	const handleShareButtonClick = useCallback(
		closetId =>
			setShowShareButtonsForCloset(currentClosetId =>
				closetId === currentClosetId ? null : closetId
			),
		[]
	);

	const popupPortalRef = useRef();

	const mijnKastenTitel = getTextContent('mijnKastenTitel');
	const mijnKastenStartNieuwButton = getTextContent('mijnKastenStartNieuwButton');
	const mijnKastenLeeg = getTextContent('mijnKastenLeeg');

	return (
		<ContentContainer>
			<Flex width={555} flexDirection="column" alignItems="center" ref={popupPortalRef}>
				<Heading2>{mijnKastenTitel}</Heading2>

				{closets.length > 0 && (
					<Pane overflowY="auto" overflowX="hidden" maxHeight="350px">
						{closets.map((closet, index) => (
							<ClosetRow
								key={closet.guid}
								closet={closet}
								getTextContent={getTextContent}
								index={index}
								isLast={index === closets.length - 1}
								showShareButtons={showShareButtonsForCloset === closet.guid}
								onShareButtonClick={handleShareButtonClick}
								onEditButtonClick={editCloset}
								onDeleteButtonClick={deleteCloset}
								popupPortalRef={popupPortalRef}
							/>
						))}
					</Pane>
				)}

				{closets.length === 0 && <Paragraph>{mijnKastenLeeg}</Paragraph>}

				<Button
					iconAlt="plus icon"
					iconUrl={plusIconUrl}
					label={mijnKastenStartNieuwButton}
					to="/aanpassen/de-kamer"
					type="primary"
				/>
			</Flex>
		</ContentContainer>
	);
}
