/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';

import ReactDOM from 'react-dom';

import deleteIconUrl from './delete-icon.svg';
import infoIconUrl from './info-icon.svg';
import rotateIconUrl from './rotate-icon.svg';
import warningIconUrl from './warning-icon.svg';
import { useState } from 'react';

const StyledTooltip = styled.div`
	position: absolute;
	top: ${props => `${props.position.y}px`};
	left: ${props => `${props.position.x}px`};
	border-radius: 10px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
	background-color: var(--dark-mauve);
	pointer-events: ${props => (props.isPermanent ? 'auto' : 'none')};
	cursor: ${props => (props.isPermanent ? 'pointer' : 'default')};
	padding: 5px;
	width: ${props => {
		if (props.width) {
			return props.width;
		}
		return props.isPermanent ? '120px' : 'auto';
	}};
	text-align: left;
`;

const RedTooltip = styled.div`
	position: absolute;
	display: flex;
	flex-direction: row;
	top: ${props => `${props.position.y}px`};
	left: ${props => `${props.position.x}px`};
	width: ${props => props.width || '200px'};
	border-radius: 10px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
	background-color: var(--pastel-red);
	padding: 5px;
	pointer-events: none;

	> img {
		flex: none;
		margin-right: 5px;
	}

	> span {
		font-family: 'Roboto';
		font-size: 12px;
		font-weight: 300;
		line-height: 1.33;
		text-align: left;
		color: #ffffff;
		white-space: pre-wrap;
	}
`;

const ProductInfo = styled.div`
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 300;
	line-height: 1.25;
	color: rgba(255, 255, 255, 0.8);

	div {
		white-space: nowrap;
	}

	.message {
		font-size: 12px;
		padding-right: 3px;
		white-space: pre-wrap;
		width: 150px;
	}

	.volume {
		font-weight: bold;
	}

	.price {
		font-weight: bold;
		color: #ffffff;
	}
`;

const TooltipMenuItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	white-space: nowrap;
	cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
	text-decoration: ${props => (props.isDisabled ? 'line-through' : 'none')};
	font-weight: normal;
	color: rgba(255, 255, 255, 0.5);
	transition: color 0.2s ease-in-out;

	${props =>
		!props.isDisabled &&
		`:hover {
			color: #ffffff;
		}`}

	img {
		margin-right: 5px;
	}
`;

export default function ProductTooltip({
	getTextContent,
	deleteProduct,
	rotateProduct,
	rotateProductIsPossible,
	isPermanent,
	openInfoModal,
	product,
	productIsTooBig,
	position,
	tooltipPortalRef,
	isOnViewClosetPage
}) {
	const [showRotateTooltip, setShowRotateTooltip] = useState(false);

	let content = null;

	const productIsTeDiep =
		getTextContent('productIsTeDiep') ||
		'Dit product is te diep voor je kast. Draai de box en probeer het opnieuw of kies een ander product.';
	const productIsTeBreed =
		getTextContent('productIsTeBreed') ||
		'Dit product is te breed voor je kast. Draai de box en probeer het opnieuw of kies een ander product.';
	const productIsTeHoog =
		getTextContent('productIsTeHoog') ||
		'Dit product is te hoog voor de schappen in je kast. Kies een ander product.';
	const productKlikVoorLink =
		getTextContent('productKlikVoorLink') ||
		'Klik voor een link met meer informatie over dit product';
	const productKlikVoorBewerken =
		getTextContent('productKlikVoorBewerken') || 'Klik om dit product te bewerken';
	const productInformatie = getTextContent('productInformatie') || 'Informatie';
	const productNietDraaienAnderProduct =
		getTextContent('productNietDraaienAnderProduct') ||
		'Er is geen ruimte om dit product te draaien. Verwijder of verplaats boxen op dit schap en probeer het opnieuw.';
	const productNietDraaienGeenRuimte =
		getTextContent('productNietDraaienGeenRuimte') ||
		'Er is geen ruimte om dit product te draaien. Verplaats deze box een beetje naar links en probeer het opnieuw.';
	const productNietDraaienTeDiep =
		getTextContent('productNietDraaienTeDiep') ||
		'Er is geen ruimte om dit product te draaien. Dit product is te diep voor je kast. Probeer het opnieuw met een ander product.';
	const buttonVerwijderen = getTextContent('buttonVerwijderen') || 'Verwijderen';

	if (productIsTooBig === 'depth') {
		content = (
			<RedTooltip position={position} width="200px">
				<img src={warningIconUrl} alt="waarschuwing icoon" width="24px" height="24px" />
				<span>{productIsTeDiep}</span>
			</RedTooltip>
		);
	} else if (productIsTooBig === 'width') {
		content = (
			<RedTooltip position={position} width="200px">
				<img src={warningIconUrl} alt="waarschuwing icoon" width="24px" height="24px" />
				<span>{productIsTeBreed}</span>
			</RedTooltip>
		);
	} else if (productIsTooBig === 'height') {
		content = (
			<RedTooltip position={position} width="200px">
				<img src={warningIconUrl} alt="waarschuwing icoon" width="24px" height="24px" />
				<span>{productIsTeHoog}</span>
			</RedTooltip>
		);
	} else if (product.type === 'PRODUCT') {
		content = (
			<StyledTooltip position={position} isPermanent={isPermanent}>
				{/* <ProductInfo
					css={css`
						padding-left: 2px;
					`}
				>
					<div className="volume">{product.volume} liter</div>
					<div className="dimensions">
						{product.widthInCm} x {product.heightInCm} x {product.depthInCm} cm
					</div>
					<div className="price">{euro(product.price)}</div>
				</ProductInfo> */}
				<ProductInfo
					css={css`
						padding-left: 2px;
					`}
				>
					{!isPermanent && <div className="message">{productKlikVoorLink}</div>}
					{isPermanent && (
						<TooltipMenuItem onClick={() => openInfoModal(product)}>
							<img src={infoIconUrl} alt="info icon" width="24px" height="24px" />{' '}
							{productInformatie}
						</TooltipMenuItem>
					)}
				</ProductInfo>
			</StyledTooltip>
		);
	} else if (product.type === 'PLACED_PRODUCT' && !isPermanent) {
		content = (
			<StyledTooltip position={position} width="120px">
				<ProductInfo
					css={css`
						padding-left: 2px;
					`}
				>
					<div className="message">
						{isOnViewClosetPage ? productKlikVoorLink : productKlikVoorBewerken}
					</div>
				</ProductInfo>
			</StyledTooltip>
		);
	} else {
		const { possible: rotateIsPossible, reason } = rotateProductIsPossible(product);

		content = (
			<StyledTooltip position={position} isPermanent={isPermanent}>
				<ProductInfo>
					{rotateProduct && (
						<TooltipMenuItem
							isDisabled={!rotateIsPossible}
							onMouseEnter={() => setShowRotateTooltip(true)}
							onMouseLeave={() => setShowRotateTooltip(false)}
							onClick={
								rotateIsPossible
									? () => {
											// console.log('ROTATE product', product);
											rotateProduct(product);
									  }
									: null
							}
						>
							<img
								src={rotateIconUrl}
								alt="draai icoon"
								width="24px"
								height="24px"
								css={css`
									opacity: ${rotateIsPossible ? 1 : 0.5};
								`}
							/>{' '}
							Draaien
							{showRotateTooltip && !rotateIsPossible && (
								<RedTooltip position={{ x: -205, y: -45 }}>
									<img
										src={warningIconUrl}
										alt="waarschuwing icoon"
										width="24px"
										height="24px"
									/>
									{reason === 'other-product' && (
										<span>{productNietDraaienAnderProduct}</span>
									)}
									{reason === 'closet-right-bound' && (
										<span>{productNietDraaienGeenRuimte}</span>
									)}
									{reason === 'closet-depth' && (
										<span>{productNietDraaienTeDiep}</span>
									)}
								</RedTooltip>
							)}
						</TooltipMenuItem>
					)}

					<TooltipMenuItem onClick={() => openInfoModal(product)}>
						<img src={infoIconUrl} alt="info icon" width="24px" height="24px" />{' '}
						{productInformatie}
					</TooltipMenuItem>

					{deleteProduct && (
						<TooltipMenuItem onClick={() => deleteProduct(product)}>
							<img src={deleteIconUrl} alt="delete icon" width="24px" height="24px" />{' '}
							{buttonVerwijderen}
						</TooltipMenuItem>
					)}
				</ProductInfo>
			</StyledTooltip>
		);
	}

	if (tooltipPortalRef && tooltipPortalRef.current) {
		return ReactDOM.createPortal(content, tooltipPortalRef.current);
	}

	return content;
}
