// eslint-disable-next-line no-unused-vars
import React from 'react';

/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';

import arrowLeftUrl from './arrow-left-icon.svg';

const linkStyles = css`
	display: flex;
	flex-direction: row;
	flex: none;
	align-items: center;
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 500;
	line-height: 1.88;
	color: var(--warm-grey);
	cursor: pointer;
	text-decoration: none;

	transition: color 0.2s ease-in-out, text-decoration 0.2s ease-in-out;
	:hover {
		color: var(--dark-mauve);
		text-decoration: underline;
	}

	img {
		flex: none;
	}
	span {
		flex: none;
	}
`;

export default function BackTextLink({ label }) {
	const history = useHistory();

	return (
		<span onClick={() => history.goBack()} css={linkStyles}>
			<img src={arrowLeftUrl} width={24} height={24} alt="arrow left icon" />
			<span>{label || 'Terug'}</span>
		</span>
	);
}
