/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import Flex from '../../box-model/Flex';
import Button from '../../button/Button';
import Heading2 from '../../typography/Heading2';
import Paragraph from '../../typography/Paragraph';

import ContentContainer from '../ContentContainer';

import plusIconUrl from './plus-icon.svg';
import archiveIconUrl from './archive-icon.svg';
import { useEffect } from 'react';

const welcomeParagraphStyles = css`
	text-align: center;
	width: 527px;
	white-space: pre-wrap;
`;

export default function WelcomePage({ currentUser, reset, getTextContent }) {
	useEffect(() => {
		reset();
	}, [reset]);

	const homeTitle = getTextContent('homeTitle');
	const homeText = getTextContent('homeText');
	const homeButtonStart = getTextContent('homeButtonStart');
	const homeButtonEdit = getTextContent('homeButtonEdit');

	return (
		<ContentContainer>
			{homeTitle && <Heading2>{homeTitle}</Heading2>}

			{homeText && <Paragraph css={welcomeParagraphStyles}>{homeText}</Paragraph>}

			<Flex flex="none" flexDirection="row" justifyContent="center" spaceHorizontal="40px">
				{homeButtonStart && (
					<Button
						iconAlt="plus icon"
						iconUrl={plusIconUrl}
						label={homeButtonStart}
						to="/beginnen"
						type="primary"
					/>
				)}

				{homeButtonEdit && (
					<Button
						iconAlt="archive icon"
						iconUrl={archiveIconUrl}
						label={homeButtonEdit}
						to={
							currentUser
								? '/mijn-kasten'
								: { pathname: '/inloggen', state: { next: '/mijn-kasten' } }
						}
						type="secondary"
					/>
				)}
			</Flex>
		</ContentContainer>
	);
}
