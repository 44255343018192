/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useState } from 'react';

const styles = css`
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 500;
	line-height: 1.88;
	text-align: center;

	cursor: pointer;
	border: none;
	outline: none;

	color: var(--dark-mauve);
	background-color: transparent;

	transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
	:hover {
		color: var(--pastel-red);
	}
`;
const isSelectedStyles = css`
	${styles}
	pointer-events: none;
	color: var(--pastel-red);
`;

const iconStyles = css`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	width: 100px;
	height: 75px;
	border-radius: 4px;
	border: solid 2px rgba(130, 90, 93, 0.25);
	background-color: #ffffff;

	img {
		margin-right: 4px;
	}
`;
const isSelectedIconStyles = css`
	${iconStyles}
	border-color: var(--pastel-red);
`;

export default function BigIconButton({
	label,
	iconAlt,
	iconUrl,
	iconSelectedUrl,
	isSelected,
	onClick
}) {
	const [isHovered, setIsHovered] = useState(false);

	return (
		<button
			css={isSelected ? isSelectedStyles : styles}
			onMouseOver={() => setIsHovered(true)}
			onMouseOut={() => setIsHovered(false)}
			onClick={onClick}
		>
			<div css={isSelected ? isSelectedIconStyles : iconStyles}>
				{!isSelected && !isHovered && <img src={iconUrl} alt={iconAlt} />}
				{(isSelected || isHovered) && <img src={iconSelectedUrl} alt={iconAlt} />}
			</div>

			<span>{label}</span>
		</button>
	);
}
