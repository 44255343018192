/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled/macro';

import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Flex from '../../box-model/Flex';

import Button from '../../button/Button';
import BackTextLink from '../../button/BackTextLink';

import CheckBoxInput from '../../input/CheckBoxInput';
import RadioButtonInput from '../../input/RadioButtonInput';
import SingleLineInput from '../../input/SingleLineInput';

import Heading2 from '../../typography/Heading2';

import ContentContainer from '../ContentContainer';

import registerIconUrl from './register-icon.svg';

const Paragraph = styled.p`
	font-family: 'Roboto';
	font-size: 14px;
	font-weight: 300;
	line-height: 1.25;
	color: ${props => (props.error ? 'var(--pastel-red)' : 'var(--warm-grey)')};
	text-align: ${props => (props.error ? 'center' : 'left')};
	margin-top: 0;
	white-space: pre-wrap;

	p {
		display: inline;
		margin: 0;
	}

	a {
		position: relative;
		color: var(--warm-grey);
		cursor: pointer;
		text-decoration: none;

		transition: color 0.2s ease-out;
		z-index: 1;

		::after {
			transition: background-color 0.2s ease-out, height 0.2s ease-out;
			content: '';
			display: block;
			position: absolute;
			z-index: -1;

			height: 12px;
			bottom: -1px;
			left: 0;
			right: 0;

			background-color: rgba(232, 90, 90, 0.2);
		}

		transition: color 0.2s ease-in-out;
		:hover {
			color: #ffffff;

			::after {
				height: 100%;
				background-color: var(--pastel-red);
			}
		}
	}
`;

const Form = Flex.withComponent('form');

export default function RegisterPage({ getTextContent, onSubmit }) {
	const location = useLocation();
	const next =
		location && location.state && location.state.next ? location.state.next : '/mijn-kasten';

	const [error, setError] = useState(false);

	const [gender, setGender] = useState(null);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [acceptedTerms, setAcceptedTerms] = useState(false);

	const handleGenderChange = useCallback(value => {
		setError(null);
		setGender(value);
	}, []);
	const handleFirstNameChange = useCallback(value => {
		setError(null);
		setFirstName(value);
	}, []);
	const handleLastNameChange = useCallback(value => {
		setError(null);
		setLastName(value);
	}, []);
	const handleEmailChange = useCallback(value => {
		setError(null);
		setEmail(value);
	}, []);
	const handlePasswordChange = useCallback(value => {
		setError(null);
		setPassword(value);
	}, []);

	const onSubmitButtonClick = useCallback(
		event => {
			event.preventDefault();
			onSubmit({ firstName, lastName, email, password }, next).then(error => {
				if (error && error.result === 'DuplicateUserName') {
					// console.log('error', error);
					setError(error);
				}
			});
		},
		[email, firstName, lastName, next, onSubmit, password]
	);

	const registrerenTitel = getTextContent('registrerenTitel');
	const registrerenMan = getTextContent('registrerenMan');
	const registrerenVrouw = getTextContent('registrerenVrouw');
	const registrerenVoornaam = getTextContent('registrerenVoornaam');
	const registrerenAchternaam = getTextContent('registrerenAchternaam');
	const inloggenEmailadres = getTextContent('inloggenEmailadres');
	const nieuwWachtwoordInstellenWachtwoord = getTextContent('nieuwWachtwoordInstellenWachtwoord');
	const buttonRegistreren = getTextContent('buttonRegistreren');
	const terug = getTextContent('terug');
	const registerenAkkoord = getTextContent('registerenAkkoord');
	const emailInGebruik = getTextContent('emailInGebruik');

	return (
		<ContentContainer>
			<Flex width={350} flexDirection="column" alignItems="center">
				<Heading2>{registrerenTitel}</Heading2>

				<Form
					flexDirection="column"
					spaceVertical="24px"
					alignSelf="stretch"
					alignItems="stretch"
				>
					<Flex
						alignSelf="stretch"
						alignItems="stretch"
						flex="1"
						flexDirection="row"
						spaceHorizontal="10px"
					>
						<RadioButtonInput
							label={registrerenMan}
							group="gender"
							value={1}
							checked={gender === 1}
							onChange={event => handleGenderChange(parseInt(event.target.value, 10))}
						/>

						<RadioButtonInput
							label={registrerenVrouw}
							group="gender"
							value={0}
							checked={gender === 0}
							onChange={event => handleGenderChange(parseInt(event.target.value, 10))}
						/>
					</Flex>

					<SingleLineInput
						placeholder={registrerenVoornaam}
						type="text"
						onChange={handleFirstNameChange}
						value={firstName}
					/>

					<SingleLineInput
						placeholder={registrerenAchternaam}
						type="text"
						onChange={handleLastNameChange}
						value={lastName}
					/>

					<SingleLineInput
						placeholder={inloggenEmailadres}
						type="email"
						onChange={handleEmailChange}
						isValid={value => value && value.includes('@') && value.includes('.')}
						autocomplete="username"
						value={email}
					/>

					<SingleLineInput
						placeholder={nieuwWachtwoordInstellenWachtwoord}
						type="password"
						onChange={handlePasswordChange}
						isValid={value => value && value.length >= 6}
						autocomplete="current-password"
						value={password}
					/>

					<Paragraph>
						<CheckBoxInput
							checked={acceptedTerms}
							onChange={event => setAcceptedTerms(acceptedTerms => !acceptedTerms)}
						/>{' '}
						<span dangerouslySetInnerHTML={{ __html: registerenAkkoord }} />
					</Paragraph>

					{error && (
						<Paragraph marginTop="0px" error>
							{emailInGebruik}
						</Paragraph>
					)}

					<Flex
						alignSelf="stretch"
						alignItems="stretch"
						flex="1"
						flexDirection="row"
						justifyContent="space-between"
					>
						<BackTextLink label={terug} />

						<Button
							label={buttonRegistreren}
							iconAlt="register icon"
							iconUrl={registerIconUrl}
							isDisabled={
								gender === null ||
								!firstName ||
								!lastName ||
								!email ||
								!email.includes('@') ||
								!email.includes('.') ||
								!password ||
								password.length < 6 ||
								!acceptedTerms
							}
							onClick={onSubmitButtonClick}
							type="primary"
						/>
					</Flex>
				</Form>
			</Flex>
		</ContentContainer>
	);
}
