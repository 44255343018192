/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled/macro';

import Block from '../../../box-model/Block';
import Flex from '../../../box-model/Flex';

import Pane from '../Pane';

import Color from './Color';

const Heading3 = styled.h3`
	font-family: 'Roboto';
	font-size: 14px;
	font-weight: bold;
	line-height: 1.43;
	text-align: left;
	margin: 0 0 5px 0;
	color: var(--purple-brown);
`;

export default function ColorsPane({
	colorOptions,
	deBoxenKleurenVoor,
	onClosePaneButtonClick,
	onColorSelect,
	seriesLabel
}) {
	return (
		<Block position="absolute" top="0px" right="-21px" transform="translateX(100%)">
			<Pane onClosePaneButtonClick={onClosePaneButtonClick} paddingBottom="2px" width="183px">
				<Heading3>{deBoxenKleurenVoor} {seriesLabel}</Heading3>

				<Flex flex="none" flexWrap="wrap" spaceHorizontal="12px" spaceVertical="12px">
					{colorOptions &&
						colorOptions.map((colorOption, index) => (
							<Color
								key={index}
								colorOption={colorOption}
								onClick={() => onColorSelect(colorOption)}
							/>
						))}
				</Flex>
			</Pane>
		</Block>
	);
}
