/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';

import closeIconUrl from './close-icon.svg';
import { forwardRef } from 'react';

const paneStyles = css`
	position: relative;

	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 5px;

	padding-top: 14px;
	padding-left: 12px;
	padding-right: 12px;

	z-index: 1;
`;

const Heading = styled.h2`
	font-family: 'Pacifico';
	font-size: 36px;
	font-weight: normal;
	line-height: 0.83;
	color: var(--purple-brown);
	margin-top: 0;
	margin-bottom: 0;
`;

export const SubHeading = styled.p`
	font-family: 'Roboto';
	font-size: 20px;
	font-weight: 500;
	line-height: 1.5;
	color: var(--pastel-red);
	margin-top: 15px;
	margin-bottom: ${props => props.marginBottom};
`;

const PaneCloseButton = styled.div`
	position: absolute;
	top: -10px;
	right: -10px;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 39px;
	height: 39px;
	border-radius: 5px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
	background-color: var(--pastel-red);
	cursor: pointer;

	transition: background-color 0.2s ease-in-out;
	:hover {
		background-color: #e33131;
	}
`;

const Pane = forwardRef(
	(
		{
			children,
			heading = '',
			onClosePaneButtonClick,
			subHeading = '',
			width = '336px',
			paddingBottom = '14px'
		},
		ref
	) => {
		return (
			<div
				ref={ref}
				css={css`
					${paneStyles}
					width: ${width};
					padding-bottom: ${paddingBottom};
				`}
			>
				<PaneCloseButton onClick={onClosePaneButtonClick}>
					<img src={closeIconUrl} width={24} height={24} alt="close icon" />
				</PaneCloseButton>

				{heading && <Heading marginBottom="24px">{heading}</Heading>}

				{subHeading && <SubHeading marginBottom="10px">{subHeading}</SubHeading>}

				{children}
			</div>
		);
	}
);

export default Pane;
