/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import display from '../../../box-model/display';

import WoodTextureHorizontal from './medium-dark-wood-texture-horizontal.png';

const styles = css`
	${display('inline-block')}

	position: absolute;

	top: 0;
	bottom: 0;
`;

export default function VerticalPlank({ isOnTheRight, thickness, textureUrl }) {
	return (
		<div
			css={[styles, { background: `url(${textureUrl || WoodTextureHorizontal})` }]}
			style={isOnTheRight ? { right: 0, width: thickness } : { left: 0, width: thickness }}
		/>
	);
}
