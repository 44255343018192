/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import display from '../box-model/display';

import ProductImage from './ProductImage';
import ProductTooltip from './ProductTooltip';

const styles = css`
	${display('inline-block')}
	flex: none;
`;

export default function Product({
	getTextContent,
	closetDepth,
	closetWidth,
	isPlaced,
	onBeginDrag,
	onIsDragging,
	deleteProduct,
	rotateProduct,
	rotateProductIsPossible = () => {},
	shelveHeight,
	openInfoModal = () => {},
	product = {},
	tooltipPortalRef,
	tooltipScrollContainerRef,
	isOnViewClosetPage
}) {
	product = {
		...product,
		type: isPlaced ? 'PLACED_PRODUCT' : 'PRODUCT'
	};

	const tooltipPortalRect = useMemo(() => {
		if (tooltipPortalRef && tooltipPortalRef.current) {
			return tooltipPortalRef.current.getBoundingClientRect();
		}
		return null;
	}, [tooltipPortalRef]);

	let productIsTooBig = null;
	if (!product.rotated && closetDepth < product.depth) {
		productIsTooBig = 'depth';
	}
	if (product.rotated && closetDepth < product.width) {
		productIsTooBig = 'width';
	}
	if (!product.rotated && closetWidth < product.width) {
		productIsTooBig = 'width';
	}
	if (product.rotated && closetWidth < product.depth) {
		productIsTooBig = 'width';
	}
	if (shelveHeight < product.height) {
		productIsTooBig = 'height';
	}

	const [{ isDragging }, connectDragSource, connectDragPreview] = useDrag({
		item: product,
		begin: () => {
			onBeginDrag(product);
		},
		canDrag: () => !productIsTooBig && !isOnViewClosetPage,
		collect: monitor => ({
			isDragging: monitor.isDragging()
		}),
		options: {
			dropEffect: isPlaced ? 'move' : 'copy'
		}
	});

	let cursor = isDragging ? 'grabbing' : 'grab';
	if (productIsTooBig && !isOnViewClosetPage) {
		cursor = 'default';
	}

	const rect = useRef();
	const handleRef = useCallback(
		domNode => {
			if (!productIsTooBig && !isOnViewClosetPage) {
				connectDragSource(domNode);
			}

			rect.current = domNode ? domNode.getBoundingClientRect() : null;
		},
		[connectDragSource, isOnViewClosetPage, productIsTooBig]
	);

	useEffect(() => {
		if (!productIsTooBig && !isOnViewClosetPage) {
			connectDragPreview(getEmptyImage());
		}
	}, [connectDragPreview, isOnViewClosetPage, productIsTooBig]);

	useEffect(() => {
		if (!productIsTooBig && onIsDragging && !isOnViewClosetPage) {
			onIsDragging(isDragging);
		}
		if (isDragging) {
			setIsTooltipVisible(false);
		}
	}, [isDragging, isOnViewClosetPage, onIsDragging, productIsTooBig]);

	const tooltipVisibleTimeoutId = useRef();
	const [isTooltipVisible, setIsTooltipVisible] = useState(false);

	const handleMouseEnter = useCallback(() => {
		clearTimeout(tooltipVisibleTimeoutId.current);
		tooltipVisibleTimeoutId.current = setTimeout(() => setIsTooltipVisible(true), 300);
	}, []);
	const handleMouseLeave = useCallback(() => {
		clearTimeout(tooltipVisibleTimeoutId.current);
		setIsTooltipVisible(false);
		setTooltipIsPermanent(false);
	}, []);

	const [tooltipPosition, setTooltipPosition] = useState({ x: 5, y: 5 });
	const handleMouseMove = useCallback(
		event => {
			let x = event.nativeEvent.offsetX;
			let y = event.nativeEvent.offsetY;
			if (tooltipPortalRect) {
				x = rect.current.left - tooltipPortalRect.left + event.nativeEvent.offsetX;
				y = rect.current.top - tooltipPortalRect.top + 100 + event.nativeEvent.offsetY;
			}
			if (tooltipScrollContainerRef && tooltipScrollContainerRef.current) {
				y -= tooltipScrollContainerRef.current.scrollTop;
			}
			setTooltipPosition({ x, y });
		},
		[tooltipPortalRect, tooltipScrollContainerRef]
	);

	const [tooltipIsPermanent, setTooltipIsPermanent] = useState(false);
	const handleClick = useCallback(() => {
		setIsTooltipVisible(!tooltipIsPermanent);
		setTooltipIsPermanent(tooltipIsPermanent => !tooltipIsPermanent);
	}, [tooltipIsPermanent]);

	useEffect(() => {
		return () => {
			clearTimeout(tooltipVisibleTimeoutId.current);
		};
	}, []);

	const content = (
		<div
			css={styles}
			style={{ cursor, zIndex: isTooltipVisible ? 1 : 0 }}
			ref={handleRef}
			draggable={!productIsTooBig}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onMouseMove={tooltipIsPermanent ? null : handleMouseMove}
			onClick={
				product.type === 'PLACED_PRODUCT' || product.type === 'PRODUCT' ? handleClick : null
			}
		>
			<ProductImage product={product} productIsTooBig={productIsTooBig} />

			{isTooltipVisible && !isDragging && (
				<ProductTooltip
					getTextContent={getTextContent}
					deleteProduct={deleteProduct}
					rotateProduct={rotateProduct}
					rotateProductIsPossible={rotateProductIsPossible}
					openInfoModal={openInfoModal}
					product={product}
					productIsTooBig={productIsTooBig}
					position={tooltipPosition}
					isPermanent={tooltipIsPermanent}
					tooltipPortalRef={tooltipPortalRef}
					isOnViewClosetPage={isOnViewClosetPage}
				/>
			)}
		</div>
	);

	return content;
}
