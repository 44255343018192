/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import display from '../../../box-model/display';

import WoodTextureHorizontal from './medium-dark-wood-texture-horizontal.png';

const styles = css`
	${display('inline-block')}

	position: absolute;

	left: 0;
	right: 0;
`;

export default function HorizontalPlank({ isOnTop, thickness, textureUrl }) {
	return (
		<div
			css={[styles, { background: `url(${textureUrl || WoodTextureHorizontal})` }]}
			style={
				isOnTop
					? { position: 'relative', height: thickness }
					: { bottom: 0, height: thickness }
			}
		/>
	);
}
