/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled/macro';

import { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import Flex from '../../box-model/Flex';

import Button from '../../button/Button';
import BackTextLink from '../../button/BackTextLink';

import SingleLineInput from '../../input/SingleLineInput';

import Heading2 from '../../typography/Heading2';

import ContentContainer from '../ContentContainer';

import loginIconUrl from './login-icon.svg';

const Paragraph = styled.p`
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 300;
	line-height: 1.25;
	color: ${props => (props.error ? 'var(--pastel-red)' : 'var(--warm-grey)')};
	text-align: center;
	margin-top: ${props => props.marginTop || '10px'};
	white-space: pre-wrap;

	a {
		position: relative;
		color: var(--warm-grey);
		cursor: pointer;
		text-decoration: none;

		transition: color 0.2s ease-out;
		z-index: 1;

		::after {
			transition: background-color 0.2s ease-out, height 0.2s ease-out;
			content: '';
			display: block;
			position: absolute;
			z-index: -1;

			height: 12px;
			bottom: -1px;
			left: 0;
			right: 0;

			background-color: rgba(232, 90, 90, 0.2);
		}

		transition: color 0.2s ease-in-out;
		:hover {
			color: #ffffff;

			::after {
				height: 100%;
				background-color: var(--pastel-red);
			}
		}
	}
`;

const Form = Flex.withComponent('form');

export default function LoginPage({ getTextContent, onSubmit }) {
	const location = useLocation();
	const next =
		location && location.state && location.state.next ? location.state.next : '/mijn-kasten';

	const [error, setError] = useState(false);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const handleEmailChange = useCallback(value => {
		setError(null);
		setEmail(value);
	}, []);
	const handlePasswordChange = useCallback(value => {
		setError(null);
		setPassword(value);
	}, []);

	const onSubmitButtonClick = useCallback(
		event => {
			event.preventDefault();
			onSubmit({ email, password }, next).catch(error => {
				// console.log('error', error);
				setError(error);
			});
		},
		[email, next, onSubmit, password]
	);

	const inloggenTitel = getTextContent('inloggenTitel');
	const inloggenEmailadres = getTextContent('inloggenEmailadres');
	const inloggenWachtwoord = getTextContent('inloggenWachtwoord');
	const buttonInloggen = getTextContent('buttonInloggen');
	const terug = getTextContent('terug');
	const inloggenFooterTekst = getTextContent('inloggenFooterTekst');
	const emailOfWachtwoordOnjuist = getTextContent('emailOfWachtwoordOnjuist');

	return (
		<ContentContainer>
			<Flex width={350} flexDirection="column" alignItems="center">
				<Heading2>{inloggenTitel}</Heading2>

				<Form
					flexDirection="column"
					spaceVertical="24px"
					alignSelf="stretch"
					alignItems="stretch"
				>
					<SingleLineInput
						placeholder={inloggenEmailadres}
						type="email"
						onChange={handleEmailChange}
						value={email}
						isValid={value => value && value.includes('@') && value.includes('.')}
						autocomplete="username"
					/>

					<SingleLineInput
						placeholder={inloggenWachtwoord}
						type="password"
						onChange={handlePasswordChange}
						value={password}
						autocomplete="current-password"
					/>

					{error && error.error && (
						<Paragraph marginTop="0px" error>
							{emailOfWachtwoordOnjuist}
						</Paragraph>
					)}

					<Flex
						alignSelf="stretch"
						alignItems="stretch"
						flex="1"
						flexDirection="row"
						justifyContent="space-between"
					>
						<BackTextLink label={terug} />

						<Button
							label={buttonInloggen}
							iconAlt="login icon"
							iconUrl={loginIconUrl}
							isDisabled={
								!email || !password || !email.includes('@') || !email.includes('.')
							}
							onClick={onSubmitButtonClick}
							type="primary"
						/>
					</Flex>
				</Form>

				<Paragraph dangerouslySetInnerHTML={{ __html: inloggenFooterTekst }} />
				{/* Wachtwoord vergeten? Vraag dan een
					<br />
					<Link to={{ pathname: '/wachtwoord-vergeten', state: { next } }}>
						nieuw wachtwoord
					</Link>{' '}
					aan.
					<br />
					Of nog geen account?{' '}
					<Link to={{ pathname: '/registreren', state: { next } }}>Registreer nu</Link>.
				</Paragraph> */}
			</Flex>
		</ContentContainer>
	);
}
