/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';

import { useCallback, useEffect, useState } from 'react';

import Block from '../../box-model/Block';
import Flex from '../../box-model/Flex';

import Slider from '../../input/Slider';

import Pane from './Pane';

import Button from '../../button/Button';

const Heading3 = styled.h3`
	font-family: 'Roboto';
	font-size: 14px;
	font-weight: bold;
	line-height: 1.43;
	text-align: left;
	margin-top: ${props => props.marginTop || '10px'};
	margin-bottom: 0;
	color: var(--purple-brown);
`;

const MaterialThumbnailSquare = styled.div`
	width: 30px;
	height: 30px;
	box-sizing: border-box;
	border: ${props =>
		props.isSelected ? 'solid 2px var(--pastel-red)' : 'solid 1px rgba(112, 112, 112, 0.5)'};
	background: url(${props => props.src});
	background-size: contain;
	cursor: pointer;

	transition: border 0.2s ease-in-out;
	:hover {
		border: solid 2px var(--pastel-red);
	}
`;

const Paragraph = styled.p`
	font-family: 'Roboto';
	font-size: 14px;
	font-weight: 300;
	line-height: 1.25;
	color: var(--warm-grey);
	margin-top: ${props => props.marginTop || '15px'};
	white-space: pre-wrap;
`;

export default function ClosetPane({
	auth,
	currentLocale,
	currentCloset,
	getTextContent,
	isDisabled,
	onClearButtonClick,
	onClosePaneButtonClick,
	setCurrentCloset
}) {
	const [materialOptions, setMaterialOptions] = useState([]);
	useEffect(() => {
		auth.fetch(`/closettexture?languageCode=${currentLocale}`)
			.then(response => response.json())
			.then(materialOptions => {
				// console.log('GET CLOSET TEXTURES RESPONSE JSON', materialOptions);

				if (materialOptions && Array.isArray(materialOptions)) {
					setMaterialOptions(materialOptions);
				} else {
					// console.log('GET CLOSET TEXTURES UNEXPECTED RESPONSE JSON', materialOptions);
				}
			})
			.catch(error => {
				// console.log('GET CLOSET TEXTURES ERROR', error);
			});
	}, [auth, currentLocale]);

	const handleSliderWidthChange = useCallback(
		value => {
			setCurrentCloset(currentCloset => ({
				...currentCloset,
				isDirty: true,
				width: value
			}));
		},
		[setCurrentCloset]
	);
	const handleSliderHeightChange = useCallback(
		value => {
			setCurrentCloset(currentCloset => ({
				...currentCloset,
				isDirty: true,
				height: value
			}));
		},
		[setCurrentCloset]
	);
	const handleSliderDepthChange = useCallback(
		value => {
			setCurrentCloset(currentCloset => ({
				...currentCloset,
				isDirty: true,
				depth: value
			}));
		},
		[setCurrentCloset]
	);
	const handleSliderShelvesChange = useCallback(
		value => {
			setCurrentCloset(currentCloset => ({
				...currentCloset,
				isDirty: true,
				totalShelves: value
			}));
		},
		[setCurrentCloset]
	);
	const handleSliderThicknessChange = useCallback(
		value => {
			setCurrentCloset(currentCloset => ({
				...currentCloset,
				isDirty: true,
				shelveThickness: value
			}));
		},
		[setCurrentCloset]
	);

	const deKastTitel = getTextContent('deKastTitel');
	const deKastSubtitel = getTextContent('deKastSubtitel');
	const deKastMateriaal = getTextContent('deKastMateriaal') || 'Materiaal';
	const deKastBreedte = getTextContent('deKastBreedte');
	const deKastHoogte = getTextContent('deKastHoogte');
	const deKastDiepte = getTextContent('deKastDiepte');
	const deKastHoeveelSchappen = getTextContent('deKastHoeveelSchappen');
	const deKastHoeDikZijnDePlanken = getTextContent('deKastHoeDikZijnDePlanken');
	const deKastLeegmakenTekst = getTextContent('deKastLeegmakenTekst');
	const deKastLeegmakenButton = getTextContent('deKastLeegmakenButton');

	return (
		<Pane
			heading={deKastTitel}
			onClosePaneButtonClick={onClosePaneButtonClick}
			subHeading={deKastSubtitel}
		>
			<Heading3 marginTop="0px">{deKastBreedte}</Heading3>
			<Slider
				value={currentCloset.width}
				onChange={handleSliderWidthChange}
				min={30}
				max={280}
				disabled={isDisabled}
				suffix="cm"
			/>

			<Heading3>{deKastHoogte}</Heading3>
			<Slider
				value={currentCloset.height}
				onChange={handleSliderHeightChange}
				min={60}
				max={240}
				disabled={isDisabled}
				suffix="cm"
			/>

			<Heading3>{deKastDiepte}</Heading3>
			<Slider
				value={currentCloset.depth}
				onChange={handleSliderDepthChange}
				min={10}
				max={100}
				disabled={isDisabled}
				suffix="cm"
			/>

			<Heading3>{deKastHoeveelSchappen}</Heading3>
			<Slider
				value={currentCloset.totalShelves}
				onChange={handleSliderShelvesChange}
				min={1}
				max={8}
				disabled={isDisabled}
			/>

			<Heading3>{deKastHoeDikZijnDePlanken}</Heading3>
			<Slider
				value={currentCloset.shelveThickness}
				onChange={handleSliderThicknessChange}
				min={1}
				max={8}
				step={0.5}
				disabled={isDisabled}
				suffix="cm"
			/>

			{isDisabled && (
				<Block>
					<Paragraph>{deKastLeegmakenTekst}</Paragraph>

					<Button onClick={onClearButtonClick} label={deKastLeegmakenButton} />
				</Block>
			)}

			<Heading3>{deKastMateriaal}</Heading3>
			<Flex
				flex="none"
				flexDirection="row"
				spaceHorizontal="15px"
				spaceVertical={materialOptions.length > 7 ? '15px' : '0px'}
				flexWrap="wrap"
			>
				{materialOptions.map(materialOption => (
					<MaterialThumbnailSquare
						key={materialOption.guid}
						src={materialOption.image}
						isSelected={currentCloset.closetTextureGuid === materialOption.guid}
						onClick={() => {
							setCurrentCloset(currentCloset => ({
								...currentCloset,
								isDirty: true,
								closetTextureGuid: materialOption.guid
							}));
						}}
					/>
				))}
			</Flex>
		</Pane>
	);
}
